<!--分账计划-->
<template>
  <a-layout-content>
    <div class="nav">
      <a-breadcrumb>
        <a-breadcrumb-item>租赁分账管理</a-breadcrumb-item>
      </a-breadcrumb>
    </div>
    <div class="content-container">
      <!-- 搜索框 -->
      <a-form :form="form" class="advanced-search-form" @submit="onSearch">
        <a-row>
          <a-col :span="7">
            <a-form-item label="分账单号">
              <a-input v-decorator="['planId']" allow-clear />
            </a-form-item>
          </a-col>
          <a-col :span="7">
            <a-form-item label="运营商">
              <a-select
                placeholder="运营商"
                v-decorator="['distributorId']"
                show-search
                allow-clear
                :filter-option="filterOption"
              >
                <a-select-option
                  :value="item.corporationId"
                  v-for="item in corporationList"
                  :key="item.corporationId"
                >
                  {{ item.corporationName }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :span="7">
            <a-form-item label="支付宝流水">
              <a-input v-decorator="['payRecordId']" allow-clear />
            </a-form-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="7">
            <a-form-item label="订单号">
              <a-input v-decorator="['tocOrderSn']" allow-clear />
            </a-form-item>
          </a-col>
          <a-col :span="7" class="search__btn">
            <a-button type="primary" icon="search" @click="onSearch">
              搜索
            </a-button>
            <a-button
              type="white"
              icon="undo"
              class="btn-reset"
              @click="onReset"
            >
              重置
            </a-button>
          </a-col>
        </a-row>
      </a-form>
      <!-- 表格 -->
      <div class="main-content">
        <a-table
          :data-source="tableData"
          :columns="columns"
          :pagination="pagination"
          @change="onPage"
          :row-key="
            (record, index) => {
              return index;
            }
          "
        >
          <template slot="createTime" slot-scope="text, record">
            <div>{{ tsFormat(record.createTime) }}</div>
          </template>
          <a-space slot="action" slot-scope="text, record">
            <a @click="onDetail(record)">查看详情</a>
          </a-space>
        </a-table>
      </div>
    </div>
  </a-layout-content>
</template>
<script>
import { organizationInfoList } from "@/services/ValueBag";
import { getShareProfitList } from "@/services/LeaseManage";
import { tsFormat } from "@/components/DateUtils";
const columns = [
  {
    title: "分账单号",
    dataIndex: "planId"
  },
  {
    title: "支付宝流水",
    dataIndex: "payRecordId"
  },
  {
    title: "订单号",
    dataIndex: "tocOrderSn"
  },
  {
    title: "待分账金额(元)",
    dataIndex: "totalAmount"
  },
  {
    title: "分账计划创建时间",
    dataIndex: "createTime",
    scopedSlots: { customRender: "createTime" }
  },
  {
    title: "状态",
    dataIndex: "planStatusValue"
  },
  {
    title: "操作",
    key: "action",
    scopedSlots: { customRender: "action" }
  }
];
export default {
  name: "AccountDivision",
  data() {
    return {
      columns,
      form: this.$form.createForm(this),
      corporationList: [],
      pagination: {
        showQuickJumper: true,
        current: 1,
        total: 0,
        pageSize: 15
      },
      tableData: []
    };
  },
  mounted() {
    this.getCorporationList();
    this.getTableList();
  },
  methods: {
    tsFormat,
    // 获取表格数据
    async getTableList() {
      const searchObj = this.form.getFieldsValue();
      let params = {
        pageNum: this.pagination.current,
        pageSize: this.pagination.pageSize,
        planId: searchObj.planId ? searchObj.planId : "",
        distributorId: searchObj.distributorId ? searchObj.distributorId : "",
        payRecordId: searchObj.payRecordId ? searchObj.payRecordId : "",
        tocOrderSn: searchObj.tocOrderSn ? searchObj.tocOrderSn : ""
      };
      const { data } = await getShareProfitList(params);
      if (data.code === "SUCCESS") {
        this.tableData = data.data.records;
        this.pagination.total = Number(data.data.total);
        this.pagination.showTotal = function(total, range) {
          return `当前显示 ${range[0]} - ${range[1]} 条, 共 ${total} 条`;
        };
      } else {
        this.$message.error(data.errorMsg);
      }
    },
    // 搜索
    onSearch() {
      this.pagination.current = 1;
      this.getTableList();
    },
    // 重置
    onReset() {
      this.form.resetFields();
      this.getTableList();
    },
    // 分页
    onPage(pagination) {
      this.pagination.current = pagination.current;
      this.getTableList();
    },
    //获取机构下拉列表
    getCorporationList() {
      organizationInfoList("endConsumerMarket").then(resp => {
        this.corporationList = resp.data.data;
      });
    },
    // 根据输入项进行筛选
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
    onDetail(item) {
      // 跳转到活动详情页
      this.$router.push({
        name: "AccountDivisionDetail",
        query: { id: item.planId }
      });
    }
  }
};
</script>
<style scoped>
.content-container {
  min-height: 1000px;
  background-color: white;
  margin: 30px;
}
.btn-reset {
  margin-bottom: 8px;
}
.search__btn {
  padding-left: 30px;
  margin-bottom: 16px;
}
.search__btn button {
  margin-right: 8px;
}
</style>
