<template>
  <a-layout-content>
    <div class="nav">
      <a-breadcrumb>
        <a-breadcrumb-item>租赁管理</a-breadcrumb-item>
        <a-breadcrumb-item>合作站长管理</a-breadcrumb-item>
      </a-breadcrumb>
    </div>
    <main class="content-container">
      <!--搜索框-->
      <a-form :form="form" class="advanced-search-form" @submit="onSearch">
        <a-row>
          <a-col :span="7">
            <a-form-item label="站长名称">
              <a-input v-decorator="['customerName']" allow-clear />
            </a-form-item>
          </a-col>
          <a-col :span="7">
            <a-form-item label="手机号">
              <a-input v-decorator="['mobile']" allow-clear />
            </a-form-item>
          </a-col>
          <a-col :span="7">
            <a-form-item label="加入时间">
              <a-range-picker allow-clear @change="onChangeTime" />
            </a-form-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="7">
            <a-form-item label="运营商">
              <a-select
                show-search
                :filter-option="filterInstitutionOption"
                placeholder="请选择渠道终端"
                v-decorator="['corporationId']"
              >
                <a-select-option
                  :value="item.corporationId"
                  v-for="item in corporationList"
                  :key="item.corporationId"
                >
                  {{ item.corporationName }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :span="7" class="search__btn">
            <a-button type="primary" icon="search" @click="onSearch">
              搜索
            </a-button>
            <a-button
              type="white"
              icon="undo"
              class="btn-reset"
              @click="onReset"
            >
              重置
            </a-button>
          </a-col>
        </a-row>
      </a-form>
      <!--表格部分-->
      <div class="main-content">
        <a-table
          :data-source="tableData"
          :columns="columns"
          :pagination="pagination"
          @change="onPage"
          :row-key="
            (record, index) => {
              return index;
            }
          "
        >
          <a-space slot="action" slot-scope="text, record">
            <a @click="onDetail(record)">详情</a>
          </a-space>
        </a-table>
      </div>
    </main>
  </a-layout-content>
</template>
<script>
const columns = [
  {
    title: "站长名称",
    dataIndex: ""
  },
  {
    title: "运营商",
    dataIndex: ""
  },
  {
    title: "站点名称",
    dataIndex: ""
  },
  {
    title: "状态",
    dataIndex: ""
  },
  {
    title: "加入时间",
    dataIndex: ""
  },
  {
    title: "操作",
    key: "action",
    scopedSlots: { customRender: "action" }
  }
];
import { organizationInfoList } from "@/services/ValueBag";
export default {
  name: "WebMasterManage",
  data() {
    return {
      form: this.$form.createForm(this),
      corporationList: [],
      startTime: "",
      endTime: "",
      pagination: {
        showQuickJumper: true,
        current: 1,
        total: 0,
        pageSize: 15
      },
      columns,
      tableData: []
    };
  },
  created() {
    this.getCorporationList();
  },
  mounted() {
    this.fetchList();
  },
  methods: {
    // 调整时间
    onChangeTime(date, dateString) {
      if (dateString && dateString.length > 0) {
        this.startTime = dateString[0];
        this.endTime = dateString[1];
      }
    },
    onSearch() {
      const searchObj = this.form.getFieldsValue();
      console.log(searchObj);
      this.fetchList();
    },
    // 重置
    onReset() {
      this.form.resetFields();
      this.startTime = "";
      this.endTime = "";
      this.fetchList();
    },
    //获取机构下拉列表
    getCorporationList() {
      organizationInfoList("retailOrder").then(resp => {
        this.corporationList = resp.data.data;
      });
    },
    // 根据机构输入项进行筛选
    filterInstitutionOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
    // 分页
    onPage(pagination) {
      this.pagination.current = pagination.current;
      this.fetchList();
    },
    // 获取表格数据
    fetchList() {
      const searchObj = this.form.getFieldsValue();
      console.log(searchObj);
      const params = {
        startTime: this.startTime,
        endTime: this.endTime,
        pageNum: this.pagination.current,
        pageSize: this.pagination.pageSize
      };
      console.log(params);
    },
    // 跳转详情页
    onDetail(record) {
      console.log(record, "详情");
      this.$router.push({
        name: "WebMasterDetail",
        query: { id: record.id || "111" }
      });
    }
  }
};
</script>
<style scoped>
.btn-reset {
  margin-bottom: 8px;
}
.search__btn {
  padding-left: 30px;
  margin-bottom: 16px;
}
.search__btn button {
  margin-right: 8px;
}
</style>
