<!--运营工单-->
<template>
  <a-layout-content>
    <div class="nav">
      <a-breadcrumb>
        <a-breadcrumb-item>运营管理</a-breadcrumb-item>
        <a-breadcrumb-item>运营监控</a-breadcrumb-item>
        <a-breadcrumb-item>运营工单</a-breadcrumb-item>
      </a-breadcrumb>
    </div>
    <div class="content-container">
      <!-- 搜索框 -->
      <a-form :form="form" class="advanced-search-form" @submit="onSearch">
        <a-row>
          <a-col :span="7">
            <a-form-item label="业务类型">
              <a-select v-decorator="['schemeMode']">
                <a-select-option
                  :value="item.value"
                  v-for="item in businessOptions"
                  :key="item.value"
                >
                  {{ item.name }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :span="7">
            <a-form-item label="商品名称">
              <a-select
                show-search
                allow-clear
                :filter-option="false"
                @search="searchProductOptions"
                placeholder="请选择"
                v-decorator="['skuId']"
              >
                <a-select-option
                  :value="item.value"
                  v-for="item in productList"
                  :key="item.value"
                >
                  {{ item.label }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :span="7">
            <a-form-item label="资产序列号">
              <a-input v-decorator="['goodsSn']" allow-clear />
            </a-form-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="7">
            <a-form-item label="订单号">
              <a-input v-decorator="['orderSn']" allow-clear />
            </a-form-item>
          </a-col>
          <a-col :span="7">
            <a-form-item label="创建时间">
              <a-range-picker
                v-decorator="['time']"
                allow-clear
                @change="onChangeTime"
              />
            </a-form-item>
          </a-col>
          <a-col :span="7">
            <a-form-item label="运营商">
              <a-select
                show-search
                allow-clear
                :filter-option="filterOption"
                placeholder="请选择渠道终端"
                v-decorator="['distributorId']"
              >
                <a-select-option
                  :value="item.corporationId"
                  v-for="item in corporationList"
                  :key="item.corporationId"
                >
                  {{ item.corporationName }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="7">
            <a-form-item label="工单状态">
              <a-select v-decorator="['operationStatus']">
                <a-select-option
                  :value="item.value"
                  v-for="item in dealOptions"
                  :key="item.value"
                >
                  {{ item.name }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :span="7" class="search__btn">
            <a-button type="primary" icon="search" @click="onSearch">
              搜索
            </a-button>
            <a-button
              type="white"
              icon="undo"
              class="btn-reset"
              @click="onReset"
            >
              重置
            </a-button>
          </a-col>
        </a-row>
      </a-form>
      <!-- 表格 -->
      <div class="main-content">
        <a-table
          :data-source="tableData"
          :columns="columns"
          :pagination="pagination"
          @change="onPage"
          :row-key="
            (record, index) => {
              return index;
            }
          "
        >
          <template slot="createTime" slot-scope="text, record">
            <div>
              {{ record.createTime ? tsFormat(record.createTime) : "--" }}
            </div>
          </template>
          <a-space slot="action" slot-scope="text, record">
            <a @click="onDetail(record)">查看详情</a>
          </a-space>
        </a-table>
      </div>
    </div>
  </a-layout-content>
</template>
<script>
import { organizationInfoList } from "@/services/ValueBag";
import { getSkuList, getWorkOrderList } from "@/services/LeaseManage";
import { tsFormat } from "@/components/DateUtils";

const columns = [
  {
    title: "商品名称",
    dataIndex: "skuName"
  },
  {
    title: "资产序列号",
    dataIndex: "goodsSn"
  },
  {
    title: "订单号",
    dataIndex: "orderSn"
  },
  {
    title: "业务类型",
    dataIndex: "schemeModeValue"
  },
  {
    title: "工单状态",
    dataIndex: "operationStatusValue"
  },
  {
    title: "处理人",
    dataIndex: "dealUserRealName"
  },
  {
    title: "运营商",
    dataIndex: "corporationName"
  },
  {
    title: "创建时间",
    dataIndex: "createTime",
    scopedSlots: { customRender: "createTime" }
  },
  {
    title: "操作",
    key: "action",
    scopedSlots: { customRender: "action" }
  }
];
export default {
  name: "WorkOrderList",
  data() {
    return {
      columns,
      form: this.$form.createForm(this),
      // 业务类型
      businessOptions: [
        { name: "全部", value: "" },
        { name: "普通租赁", value: "rent" },
        { name: "分期购车", value: "lease_purchase" }
      ],
      productList: [], // 商品列表
      // 工单状态
      dealOptions: [
        { name: "全部", value: "" },
        { name: "待处理", value: "todo" },
        { name: "已处理", value: "done" }
      ],
      corporationList: [], // 运营商列表
      pagination: {
        showQuickJumper: true,
        current: 1,
        total: 0,
        pageSize: 15
      },
      startTime: "",
      endTime: "",
      tableData: []
    };
  },
  created() {
    this.getCorporationList();
    this.getProductList();
  },
  mounted() {
    this.getTableList();
  },
  methods: {
    tsFormat,
    // 获取表格数据
    async getTableList() {
      const searchObj = this.form.getFieldsValue();
      const params = {
        goodsSn: searchObj.goodsSn ? searchObj.goodsSn : "",
        skuId: searchObj.skuId ? searchObj.skuId : "",
        orderSn: searchObj.orderSn ? searchObj.orderSn : "",
        schemeMode: searchObj.schemeMode ? searchObj.schemeMode : "",
        operationStatus: searchObj.operationStatus
          ? searchObj.operationStatus
          : "",
        distributorId: searchObj.distributorId ? searchObj.distributorId : "",
        startTime: this.startTime,
        endTime: this.endTime,
        pageNum: this.pagination.current,
        pageSize: this.pagination.pageSize
      };
      const { data } = await getWorkOrderList(params);
      if (data.code === "SUCCESS") {
        this.tableData = data.data.records;
        this.pagination.total = Number(data.data.total);
        this.pagination.showTotal = function(total, range) {
          return `当前显示 ${range[0]} - ${range[1]} 条, 共 ${total} 条`;
        };
      } else {
        this.$message.error(data.errorMsg);
      }
    },
    // 获取产品列表
    async getProductList(name = "") {
      const { data } = await getSkuList({
        pageSize: 100,
        pageNum: 1,
        productType: "",
        skuName: name
      });
      this.productList = data.data.records.map(item => {
        return {
          label: item.skuName,
          value: item.skuId
        };
      });
    },
    // 搜索产品
    searchProductOptions(value) {
      this.getProductList(value);
    },
    // 调整时间
    onChangeTime(date, dateString) {
      if (dateString && dateString.length > 0) {
        this.startTime = dateString[0] ? dateString[0] + " 00:00:00" : "";
        this.endTime = dateString[1] ? dateString[1] + " 23:59:59" : "";
      }
    },
    //获取机构下拉列表
    getCorporationList() {
      organizationInfoList("endConsumerMarket").then(resp => {
        this.corporationList = resp.data.data;
      });
    },
    // 根据输入项进行筛选
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
    // 搜索
    onSearch() {
      this.pagination.current = 1;
      this.getTableList();
    },
    // 重置
    onReset() {
      this.form.resetFields();
      this.startTime = "";
      this.endTime = "";
      this.getTableList();
    },
    // 分页
    onPage(pagination) {
      this.pagination.current = pagination.current;
      this.getTableList();
    },
    onDetail(item) {
      // 跳转到活动详情页
      this.$router.push({
        name: "WorkOrderDetail",
        query: { id: item.recordId }
      });
    }
  }
};
</script>
<style scoped>
.content-container {
  min-height: 1000px;
  background-color: white;
  margin: 30px;
}
.btn-reset {
  margin-bottom: 8px;
}
.search__btn {
  padding-left: 30px;
  margin-bottom: 16px;
}
.search__btn button {
  margin-right: 8px;
}
</style>
