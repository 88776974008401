<template>
  <a-layout>
    <div class="nav">
      <Breadcrumb>
        <BreadcrumbItem>计划详情</BreadcrumbItem>
      </Breadcrumb>
    </div>
    <section class="card__container">
      <a-form class="advanced-search-form">
        <a-row>
          <a-col :span="8">
            <a-form-item label="分账单号">
              <div>{{ info.planId }}</div>
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="订单号">
              <a @click="toLeaseOrder(info.tocOrderSn)">
                {{ info.tocOrderSn }}
              </a>
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
    </section>
    <section class="card__container">
      <div class="container__title">支付记录</div>
      <a-form class="advanced-search-form">
        <a-row>
          <a-col :span="8">
            <a-form-item label="支付流水号">
              <div>{{ info.payRecordId }}</div>
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="支付时间">
              <div>{{ tsFormat(info.paymentTime) }}</div>
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="支付渠道">
              <div>{{ info.paymentChannel }}</div>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="8">
            <a-form-item label="支付方式">
              <div>{{ info.payTypeValue }}</div>
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
      <div class="container__title">款项说明</div>
      <a-table
        class="small__table"
        :columns="itemColumns"
        :data-source="info.paymentDescription"
        :pagination="false"
        :rowKey="(record, index) => index"
      >
        <template slot="paymentAmount" slot-scope="text, record">
          <div>{{ record.paymentAmount.toFixed(2) }}</div>
        </template>
        <template slot="footer">
          <div>合计(元): {{ totalMount.toFixed(2) }}</div>
        </template>
      </a-table>
    </section>
    <section class="card__container">
      <div class="container__title">分账明细</div>
      <a-table
        :columns="accountColumns"
        :data-source="info.profitRecordVO"
        :pagination="false"
        :rowKey="(record, index) => index"
      >
        <template slot="planTime" slot-scope="text, record">
          <div>{{ tsFormat(record.planTime) }}</div>
        </template>
        <template slot="actualTime" slot-scope="text, record">
          <div>{{ tsFormat(record.actualTime) }}</div>
        </template>
      </a-table>
    </section>
  </a-layout>
</template>
<script>
import { getShareProfitDetail } from "@/services/LeaseManage";
import { tsFormat } from "@/components/DateUtils";
import Breadcrumb from "@/components/Breadcrumb.vue";
import BreadcrumbItem from "@/components/BreadcrumbItem.vue";
const itemColumns = [
  {
    title: "项目",
    dataIndex: "billTypeValue"
  },
  {
    title: "金额(元)",
    dataIndex: "paymentAmount",
    scopedSlots: { customRender: "paymentAmount" }
  }
];
const accountColumns = [
  {
    title: "分账方",
    dataIndex: "corporationName"
  },
  {
    title: "分账方接收账号",
    dataIndex: "aliUser"
  },
  {
    title: "分账金额(元)",
    dataIndex: "amount"
  },
  {
    title: "计划分账时间",
    dataIndex: "planTime",
    scopedSlots: { customRender: "planTime" }
  },
  {
    title: "实际分账时间",
    dataIndex: "actualTime",
    scopedSlots: { customRender: "actualTime" }
  },
  {
    title: "状态",
    dataIndex: "shareProfitStatusValue"
  }
];
export default {
  name: "AccountDivisionDetail",
  components: { BreadcrumbItem, Breadcrumb },
  data() {
    return {
      itemColumns,
      accountColumns,
      id: this.$route.query.id,
      info: {},
      totalMount: 0 // 合计金额
    };
  },
  mounted() {
    if (this.id) {
      //   获取详情
      this.getDetail();
    }
  },
  methods: {
    tsFormat,
    //获取详情
    async getDetail() {
      const { data } = await getShareProfitDetail(this.id);
      if (data.code === "SUCCESS") {
        this.info = data.data;
        this.totalMount = data.data.paymentDescription.reduce((prev, cur) => {
          return prev + cur.paymentAmount;
        }, 0);
      } else {
        this.$message.error(data.errorMsg);
      }
    },
    // 跳转租赁订单
    toLeaseOrder(orderSn) {
      let routeData = this.$router.resolve({
        name: "TrialLeaseOrderDxsj",
        query: { orderSn: orderSn }
      });
      window.open(routeData.href, "_blank");
    }
  }
};
</script>
<style scoped>
.container__title {
  margin-bottom: 10px;
}
.small__table {
  width: 600px;
}
</style>
