import { apiClient, downloadApiClient } from "./HttpService";

// -----租赁参数配置相关接口-----

// 获取租赁参数配置详情
export function getBasicConfig() {
  return apiClient.get(`/basics-config`);
}
// 修改租赁参数配置
export function editBasicConfig(params) {
  return apiClient.put(`/basics-config`, params);
}

// -----运营监控相关接口-----

//运营总部查询租赁资产管理列表
export function getWebAssetList(params) {
  return apiClient.get(
    `/lease-asset/release/web-asset?leaseAssetType=${
      params.leaseAssetType
    }&pageSize=${params.pageSize}&pageNum=${params.pageNum}&goodsSn=${
      params.goodsSn
    }&skuId=${params.skuId}&licencePlate=${params.licencePlate}&areaCode=${
      params.areaCode
    }&corporationId=${params.corporationId}&status=${
      params.status
    }&leaseStartTime=${params.leaseStartTime}&leaseEndTime=${
      params.leaseEndTime
    }&siteId=${params.siteId ? params.siteId : ""}`
  );
}
//运营总部查询租赁资产详情
export function getWebAssetDetail(leaseAssetId) {
  return apiClient.get(`/lease-asset/release/web-asset/${leaseAssetId}`);
}

// 查询产品列表
export function getProductList(productType) {
  return apiClient.get(`/products/list/?productType=${productType}`);
}

// -----运营监控工单相关接口-----
// 运营总部查询运维工单列表
export function getOperationOrderList(params) {
  return apiClient.get(
    `/maintenance/manage/operation-headquarters/record-info?pageSize=${params.pageSize}&pageNum=${params.pageNum}&goodsSn=${params.goodsSn}&maintenanceType=${params.maintenanceType}&maintenanceStatus=${params.maintenanceStatus}&startTime=${params.startTime}&endTime=${params.endTime}&corporationId=${params.corporationId}`
  );
}
// 获取运维工单详情
export function getOperationOrderDetail(recordId) {
  return apiClient.get(`/maintenance/manage/${recordId}`);
}
// 运营总部查询运营工单列表
export function getWorkOrderList(params) {
  return apiClient.get(
    `/operation/web-operation?pageSize=${params.pageSize}&pageNum=${params.pageNum}&skuId=${params.skuId}&goodsSn=${params.goodsSn}&orderSn=${params.orderSn}&startTime=${params.startTime}&endTime=${params.endTime}&schemeMode=${params.schemeMode}&operationStatus=${params.operationStatus}&distributorId=${params.distributorId}`
  );
}
// 获取运营工单详情
export function getWorkOrderDetail(recordId) {
  return apiClient.get(`/operation/web-operation/${recordId}`);
}
// 运营总部查询运营警报列表
export function getOperationAlarmList(params) {
  return apiClient.get(
    `/warning/web-warning?pageSize=${params.pageSize}&pageNum=${params.pageNum}&skuId=${params.skuId}&goodsSn=${params.goodsSn}&warningType=${params.warningType}&startTime=${params.startTime}&endTime=${params.endTime}&schemeMode=${params.schemeMode}&dealName=${params.dealName}&distributorId=${params.distributorId}&warningStatus=${params.warningStatus}`
  );
}
// 运营总部查询运营警报详情
export function getOperationAlarmDetail(recordId) {
  return apiClient.get(`/warning/web-warning/${recordId}`);
}

// -----分账计划列表相关接口-----
// 分账计划列表
export function getShareProfitList(params) {
  return apiClient.get(
    `/share-profit?pageSize=${params.pageSize}&pageNum=${params.pageNum}&planId=${params.planId}&distributorId=${params.distributorId}&payRecordId=${params.payRecordId}&tocOrderSn=${params.tocOrderSn}`
  );
}
// 分账计划详情
export function getShareProfitDetail(planId) {
  return apiClient.get(`/share-profit/${planId}`);
}

// -----运营总部查询租赁订单列相关接口-----
// 租赁订单列表(电行世界)
export function getDXSJLeaseOrderList(params) {
  return apiClient.get(
    `/lease/order/web-tocOrder?pageSize=${params.pageSize}&pageNum=${params.pageNum}&orderSn=${params.orderSn}&userName=${params.userName}&goodsSn=${params.goodsSn}&orderType=${params.orderType}&orderStatus=${params.orderStatus}&distributorId=${params.distributorId}&siteId=${params.siteId}&billStatus=${params.billStatus}&provinceCode=${params.provinceCode}&cityCode=${params.cityCode}&countyCode=${params.countyCode}&startTime=${params.startTime}&endTime=${params.endTime}`
  );
}
// 租赁订单详情(电行世界)
export function getDXSJLeaseOrderDetail(orderSn) {
  return apiClient.get(`/lease/order/web-tocOrder/${orderSn}`);
}

// 获取商品信息下拉列表
export function getSkuList(params) {
  return apiClient.get(
    `/sku/option/sku-simple?pageSize=${params.pageSize}&pageNum=${
      params.pageNum
    }&productType=${params.productType}&skuName=${
      params.skuName ? params.skuName : ""
    }`
  );
}

// 查询账单详情
export function getBillInfoDetail(orderSn) {
  return apiClient.get(`/order-bill/${orderSn}?type=pc`);
}

// 查询支付流水
export function getPaymentInfo(orderSn) {
  return apiClient.get(`/payment-statement/${orderSn}?type=pc`);
}

// 根据流水id查询支付明细
export function getBillDetailByPaymentId(paymentId) {
  return apiClient.get(`/payment-statement/${paymentId}/bill-detail`);
}

// 订单导出
export function exportOrdersExcel(params) {
  return downloadApiClient.get(
    `/lease/order/web-tocOrder/export?orderSn=${params.orderSn}&userName=${params.userName}&goodsSn=${params.goodsSn}&startTime=${params.startTime}&endTime=${params.endTime}&orderType=${params.orderType}&orderStatus=${params.orderStatus}&distributorId=${params.distributorId}&siteId=${params.siteId}&billStatus=${params.billStatus}&provinceCode=${params.provinceCode}&cityCode=${params.cityCode}&countyCode=${params.countyCode}`
  );
}
