<template>
  <a-layout-content>
    <div class="nav">
      <Breadcrumb>
        <BreadcrumbItem>站点详情</BreadcrumbItem>
      </Breadcrumb>
    </div>
    <section class="info-container">
      <a-row>
        <a-col><div class="info-title">基本信息</div></a-col>
      </a-row>
      <a-form :form="infoForm">
        <a-row>
          <a-col :span="8">
            <a-form-item label="站点名称" class="form-item">
              {{ infoForm.siteName }}
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="地理位置" class="form-item">
              <a style="color: #3769ff;cursor: pointer" @click="openMap">
                {{ infoForm.areaName }}{{ infoForm.address }}
              </a>
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="运营开始日期" class="form-item">
              {{ infoForm.openTime ? dtFormat(infoForm.openTime) : "--" }}
            </a-form-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="8">
            <a-form-item label="运营商" class="form-item">
              {{ infoForm.corporationName }}
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="运营状态" class="form-item">
              <span
                :style="
                  infoForm.statusValue === '运营中'
                    ? 'color:#06A000'
                    : 'color:#FF6430'
                "
              >
                {{ infoForm.statusValue }}
              </span>
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="站点管理员" class="form-item">
              {{ infoForm.siteAdminName }}
            </a-form-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="8">
            <a-form-item label="管理员联系方式" class="form-item">
              {{ infoForm.siteAdminPhone }}
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item
              label="车辆信息"
              class="form-item"
              style="color: #3769ff;cursor: pointer"
            >
              <a @click="toCarManage">查看</a>
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item
              label="订单信息"
              class="form-item"
              style="color: #3769ff;cursor: pointer"
            >
              <a @click="toOrderList">查看</a>
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
    </section>
    <a-modal
      :visible="mapVisible"
      :footer="null"
      title="电池当前位置"
      width="900px"
      @cancel="mapVisible = false"
    >
      <div id="container"></div>
    </a-modal>
  </a-layout-content>
</template>

<script>
import AMapLoader from "@amap/amap-jsapi-loader";
window._AMapSecurityConfig = {
  securityJsCode: "8bd908ead940215404427b8f61e8e493"
};
import { operationHeadquartersSiteInfoDetail } from "@/services/ValueBag";
import { dtFormat } from "@/components/DateUtils";
import { imageBaseUrl } from "@/services/HttpService";
import Breadcrumb from "@/components/Breadcrumb.vue";
import BreadcrumbItem from "@/components/BreadcrumbItem.vue";
export default {
  name: "ServiceSiteDetail",
  components: { BreadcrumbItem, Breadcrumb },
  data() {
    return {
      dtFormat,
      id: this.$route.query.siteId,
      infoForm: {},
      mapVisible: false,
      nowAddress: [],
      map: null,
      imageBaseUrl
    };
  },
  mounted() {
    if (this.id) {
      this.getDetail();
    }
  },
  methods: {
    getDetail() {
      operationHeadquartersSiteInfoDetail(this.id).then(resp => {
        this.infoForm = resp.data.data;
        this.nowAddress = [resp.data.data.longitude, resp.data.data.latitude];
      });
    },
    // 展示地图弹窗
    openMap() {
      this.mapVisible = true;
      if (!this.map) {
        this.initMap();
      }
    },
    // 初始化地图
    initMap() {
      AMapLoader.load({
        key: "8ff794d16e2643c4d0ef597224524cb3", // 申请好的Web端开发者Key，首次调用 load 时必填
        version: "2.0", // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
        plugins: [
          "AMap.AutoComplete",
          "AMap.PlaceSearch",
          "AMap.Scale",
          "AMap.OverView",
          "AMap.ToolBar",
          "AMap.MapType",
          "AMap.PolyEditor",
          "AMap.Geocoder",
          "AMap.AutoComplete",
          "AMap.CircleEditor",
          "AMap.Driving",
          "AMap.service",
          "AMap.DistrictSearch",
          "AMap.Geolocation",
          "AMap.Marker",
          "AMap.LngLat",
          "AMap.Polygon",
          "AMap.Circle"
        ] // 需要使用的的插件列表，如比例尺'AMap.Scale'等
      }).then(AMap => {
        this.map = new AMap.Map("container", {
          // 设置地图容器id
          enableHighAccuracy: true,
          subdomains: [],
          viewMode: "2D", // 是否为3D地图模式
          zoom: 16, // 初始化地图级别
          center: this.nowAddress // 初始化地图中心点位置
        });

        const marker = new AMap.Marker({
          position: new AMap.LngLat(
            this.infoForm.longitude,
            this.infoForm.latitude
          ),
          offset: new AMap.Pixel(0, 0),
          icon: imageBaseUrl + "/menu-icon/workIcon.png", // 添加 Icon 图标 URL
          title: ""
        });
        this.map.add(marker);

        const _this = this;
        this.map.on("moveend", function() {
          _this.nowAddress = [this.getCenter().lng, this.getCenter().lat];
        });
      });
    },
    // 跳转至运营监控-车辆管理列表，列表已自动根据站点做筛选
    toCarManage() {
      let routeData = this.$router.resolve({
        name: "CarManage",
        query: { siteId: this.infoForm.siteId }
      });
      window.open(routeData.href, "_blank");
    },
    // 跳转至c端数据-租赁订单列表，列表已自动根据站点做筛选
    toOrderList() {
      let routeData = this.$router.resolve({
        name: "TrialLeaseOrderDxsj",
        query: { siteId: this.infoForm.siteId }
      });
      window.open(routeData.href, "_blank");
    }
  }
};
</script>

<style scoped>
#container {
  width: 100%;
  height: 500px;
}
.info-container {
  background-color: white;
  margin: 10px 20px;
  box-sizing: border-box;
  padding: 25px 30px 25px 30px;
}
.info-title {
  font-weight: bold;
  margin-bottom: 10px;
}
/deep/ .ant-form-item {
  display: flex;
}
.examine-container {
  background-color: white;
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  padding: 10px;
  z-index: 1;
  display: flex;
  justify-content: center;
}
</style>
