import { render, staticRenderFns } from "./EditMaster.vue?vue&type=template&id=72cb6327&scoped=true&"
import script from "./EditMaster.vue?vue&type=script&lang=js&"
export * from "./EditMaster.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "72cb6327",
  null
  
)

export default component.exports