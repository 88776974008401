<template>
  <div class="page__container">
    <div id="container"></div>
  </div>
</template>

<script>
import AMapLoader from "@amap/amap-jsapi-loader";
window._AMapSecurityConfig = {
  securityJsCode: "8bd908ead940215404427b8f61e8e493"
};
export default {
  name: "CurrentLocation",
  data() {
    return {
      nowAddress: [this.$route.query.longitude, this.$route.query.latitude]
    };
  },
  mounted() {
    this.initMap();
  },
  methods: {
    onBack() {
      this.$router.back();
    },
    initMap() {
      // 信息窗体的内容
      const content = [
        `<div style='width:10rem;'> ${this.$route.query.currentPosition}</div>`
      ];
      AMapLoader.load({
        key: "8ff794d16e2643c4d0ef597224524cb3", // 申请好的Web端开发者Key，首次调用 load 时必填
        version: "2.0", // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
        plugins: [
          "AMap.AutoComplete",
          "AMap.PlaceSearch",
          "AMap.Scale",
          "AMap.OverView",
          "AMap.ToolBar",
          "AMap.MapType",
          "AMap.PolyEditor",
          "AMap.Geocoder",
          "AMap.AutoComplete",
          "AMap.CircleEditor",
          "AMap.Driving",
          "AMap.service",
          "AMap.DistrictSearch",
          "AMap.Geolocation",
          "AMap.Marker",
          "AMap.LngLat",
          "AMap.Polygon",
          "AMap.Circle"
        ] // 需要使用的的插件列表，如比例尺'AMap.Scale'等
      }).then(AMap => {
        const map = new AMap.Map("container", {
          // 设置地图容器id
          enableHighAccuracy: true,
          subdomains: [],
          content: content,
          viewMode: "2D", // 是否为3D地图模式
          zoom: 16, // 初始化地图级别
          center: this.nowAddress // 初始化地图中心点位置
        });
        let infoWindow = new AMap.InfoWindow({
          content: content.join("") //使用默认信息窗体框样式，显示信息内容
        });
        infoWindow.open(map, map.getCenter());
        const marker = new AMap.Marker({
          position: new AMap.LngLat(
            this.$route.query.longitude,
            this.$route.query.latitude
          ),
          offset: new AMap.Pixel(-10, -10),
          icon: "//vdata.amap.com/icons/b18/1/2.png" // 添加 Icon 图标 URL
        });
        map.add(marker);
        marker.on("click", clickHandler);
        const _this = this;
        function clickHandler() {
          var position = new AMap.LngLat(
            _this.$route.query.longitude,
            _this.$route.query.latitude
          );
          infoWindow.open(map, position);
        }
      });
    }
  }
};
</script>

<style scoped>
#container {
  width: 100%;
  height: 100vh;
}
</style>
