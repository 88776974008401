<template>
  <a-layout-content>
    <div class="nav">
      <a-breadcrumb>
        <a-breadcrumb-item>运营管理</a-breadcrumb-item>
        <a-breadcrumb-item>运营监控</a-breadcrumb-item>
        <a-breadcrumb-item>电池管理</a-breadcrumb-item>
      </a-breadcrumb>
    </div>
    <div class="content-container">
      <!--搜索框-->
      <a-form :form="form" class="advanced-search-form" @submit="onSearch">
        <a-row>
          <a-col :span="7">
            <a-form-item label="商品名称">
              <a-select
                placeholder="请选择商品名称"
                v-decorator="['skuId']"
                show-search
                allow-clear
                :filter-option="false"
                @search="searchProductOptions"
              >
                <a-select-option
                  :value="item.value"
                  v-for="item in productOptions"
                  :key="item.value"
                >
                  {{ item.label }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :span="7">
            <a-form-item label="序列号">
              <a-input v-decorator="['goodsSn']" allow-clear />
            </a-form-item>
          </a-col>
          <a-col :span="7">
            <a-form-item label="运营状态">
              <a-select placeholder="请选择运营状态" v-decorator="['status']">
                <a-select-option
                  :value="item.value"
                  v-for="item in businessOptions"
                  :key="item.value"
                >
                  {{ item.name }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="7">
            <a-form-item label="运营商">
              <a-select
                placeholder="运营商"
                v-decorator="['corporationId']"
                show-search
                allow-clear
                :filter-option="filterOption"
              >
                <a-select-option
                  :value="item.corporationId"
                  v-for="item in corporationList"
                  :key="item.corporationId"
                >
                  {{ item.corporationName }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :span="7">
            <a-form-item label="投放时间">
              <a-range-picker
                allow-clear
                @change="onChangeTime"
                v-decorator="['time']"
              />
            </a-form-item>
          </a-col>
          <a-col :span="7">
            <a-form-item label="经营地区">
              <a-cascader
                v-decorator="['areaCode']"
                change-on-select
                :options="areaTreeData"
                :field-names="areaTreeNames"
                placeholder="请选择区域"
                allow-clear
              />
            </a-form-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="7" class="search__btn">
            <a-button type="primary" icon="search" @click="onSearch">
              搜索
            </a-button>
            <a-button
              type="white"
              icon="undo"
              class="btn-reset"
              @click="onReset"
            >
              重置
            </a-button>
          </a-col>
        </a-row>
      </a-form>
      <!--表格部分-->
      <div class="main-content">
        <a-table
          :data-source="tableData"
          :columns="columns"
          :pagination="pagination"
          @change="onPage"
          :row-key="
            (record, index) => {
              return index;
            }
          "
        >
          <template slot="leaseTime" slot-scope="text, record">
            <div>
              {{ record.leaseTime ? tsFormat(record.leaseTime) : "--" }}
            </div>
          </template>
          <a-space slot="action" slot-scope="text, record">
            <a @click="onDetail(record)">详情</a>
          </a-space>
        </a-table>
      </div>
    </div>
  </a-layout-content>
</template>
<script>
import { loadArea } from "@/services/DealerService";
import { organizationInfoList } from "@/services/ValueBag";
import { getWebAssetList, getSkuList } from "@/services/LeaseManage";
import { tsFormat } from "@/components/DateUtils";
const columns = [
  {
    title: "商品名称",
    dataIndex: "skuName"
  },
  {
    title: "电池序列号",
    dataIndex: "goodsSn"
  },
  {
    title: "运营商",
    dataIndex: "corporationName"
  },
  {
    title: "经营地区",
    dataIndex: "registeredAreaName"
  },
  {
    title: "运营状态",
    dataIndex: "operationStatusValue"
  },
  {
    title: "投放时间",
    dataIndex: "leaseTime",
    scopedSlots: { customRender: "leaseTime" }
  },
  {
    title: "操作",
    key: "action",
    scopedSlots: { customRender: "action" }
  }
];
export default {
  name: "BatteryManage",
  data() {
    return {
      form: this.$form.createForm(this),
      corporationList: [],
      areaTreeData: [], // 行政区数据
      areaTreeNames: {
        label: "areaName",
        value: "areaCode",
        children: "subAreas"
      }, // 行政区树格式
      startTime: "",
      endTime: "",
      productOptions: [], // 产品名称选项们
      // 运营状态
      businessOptions: [
        { name: "全部", value: "" },
        { name: "租用中", value: "rent" },
        { name: "空闲", value: "leisure" },
        { name: "未投放", value: "unreleased" },
        { name: "分期购买中", value: "in_installment" }
      ],
      columns,
      pagination: {
        showQuickJumper: true,
        current: 1,
        total: 0,
        pageSize: 15
      },
      tableData: []
    };
  },
  created() {
    this.onLoadArea();
    this.getCorporationList();
    this.getProductList();
  },
  mounted() {
    this.fetchList();
  },
  methods: {
    tsFormat,
    // 获取产品列表
    async getProductList(name = "") {
      const { data } = await getSkuList({
        pageSize: 100,
        pageNum: 1,
        productType: "配件",
        skuName: name
      });
      this.productOptions = data.data.records.map(item => {
        return {
          label: item.skuName,
          value: item.skuId
        };
      });
    },
    // 搜索产品
    searchProductOptions(value) {
      this.getProductList(value);
    },
    // 加载行政区数据
    onLoadArea() {
      this.areaTreeData = [];
      loadArea().then(resp => {
        if (resp.data.code === "SUCCESS") {
          this.areaTreeData = resp.data.data;
        }
      });
    },
    //获取机构下拉列表
    getCorporationList() {
      organizationInfoList("endConsumerMarket").then(resp => {
        this.corporationList = resp.data.data;
      });
    },
    // 根据输入项进行筛选
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
    // 调整时间
    onChangeTime(date, dateString) {
      if (dateString && dateString.length > 0) {
        this.startTime = dateString[0] ? dateString[0] + " 00:00:00" : "";
        this.endTime = dateString[1] ? dateString[1] + " 23:59:59" : "";
      }
    },
    // 搜索
    onSearch() {
      this.pagination.current = 1;
      this.fetchList();
    },
    // 重置
    onReset() {
      this.form.resetFields();
      this.startTime = "";
      this.endTime = "";
      this.fetchList();
    },
    // 分页
    onPage(pagination) {
      this.pagination.current = pagination.current;
      this.fetchList();
    },
    // 获取表格数据
    async fetchList() {
      const searchObj = this.form.getFieldsValue();
      const params = {
        leaseAssetType: "battery", // 电池
        skuId: searchObj.skuId ? searchObj.skuId : "",
        goodsSn: searchObj.goodsSn ? searchObj.goodsSn : "",
        licencePlate: "",
        status: searchObj.status ? searchObj.status : "",
        corporationId: searchObj.corporationId ? searchObj.corporationId : "",
        leaseStartTime: this.startTime,
        leaseEndTime: this.endTime,
        pageNum: this.pagination.current,
        pageSize: this.pagination.pageSize
      };
      if (this.form.getFieldsValue().areaCode) {
        if (this.form.getFieldsValue().areaCode.length === 0) {
          params.areaCode = "";
        }
        if (this.form.getFieldsValue().areaCode.length >= 1) {
          params.areaCode = this.form.getFieldsValue().areaCode[0];
        }
        if (this.form.getFieldsValue().areaCode.length >= 2) {
          params.areaCode = this.form.getFieldsValue().areaCode[1];
        }
        if (this.form.getFieldsValue().areaCode.length >= 3) {
          params.areaCode = this.form.getFieldsValue().areaCode[2];
        }
      } else {
        params.areaCode = "";
      }
      const { data } = await getWebAssetList(params);
      if (data.code === "SUCCESS") {
        this.tableData = data.data.records;
        this.pagination.total = data.data.total;
        this.pagination.showTotal = function(total, range) {
          return `当前显示 ${range[0]} - ${range[1]} 条, 共 ${total} 条`;
        };
      } else {
        this.$message.error(data.errorMsg);
      }
    },
    // 跳转详情页
    onDetail(record) {
      this.$router.push({
        name: "BatteryDetail",
        query: {
          id: record.leaseAssetId
        }
      });
    }
  }
};
</script>

<style scoped>
.content-container {
  min-height: 1000px;
  background-color: white;
  margin: 30px;
}
.btn-reset {
  margin-bottom: 8px;
}
.search__btn {
  padding-left: 30px;
  margin-bottom: 16px;
}
.search__btn button {
  margin-right: 8px;
}
</style>
