<template>
  <a-layout>
    <div class="nav">
      <a-breadcrumb>
        <a-breadcrumb-item>配置管理</a-breadcrumb-item>
        <a-breadcrumb-item>站点管理</a-breadcrumb-item>
      </a-breadcrumb>
    </div>
    <div class="container">
      <section class="search__container">
        <a-form :form="form" labelAlign="left" class="advanced-search-form">
          <a-row>
            <a-col :span="7">
              <a-form-item
                :label-col="{ span: 4 }"
                :wrapper-col="{ span: 16 }"
                label="站点名称"
              >
                <a-input v-decorator="['siteName']" placeholder="" />
              </a-form-item>
            </a-col>
            <a-col :span="7">
              <a-form-item label="运营商">
                <a-select
                  show-search
                  :filter-option="filterInstitutionOption"
                  placeholder="请选择机构"
                  v-decorator="['corporationId']"
                  allow-clear
                >
                  <a-select-option
                    :value="item.corporationId"
                    v-for="item in corporationList"
                    :key="item.corporationId"
                  >
                    {{ item.corporationName }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :span="7">
              <a-form-item
                :label-col="{ span: 4 }"
                :wrapper-col="{ span: 16 }"
                label="运营状态"
              >
                <a-select
                  v-decorator="['status']"
                  :options="statusList"
                ></a-select>
              </a-form-item>
            </a-col>
          </a-row>
          <a-row>
            <a-col :span="7">
              <a-form-item
                :label-col="{ span: 4 }"
                :wrapper-col="{ span: 16 }"
                label="站长名称"
              >
                <a-input v-decorator="['siteAdminName']" placeholder="" />
              </a-form-item>
            </a-col>
            <a-col :span="7">
              <a-form-item
                :label-col="{ span: 4 }"
                :wrapper-col="{ span: 16 }"
                label="站长联系方式"
              >
                <a-input v-decorator="['phone']" placeholder="" />
              </a-form-item>
            </a-col>
            <a-col :span="7">
              <a-form-item label="运营时间">
                <a-range-picker
                  style="width: 100%"
                  v-decorator="['time']"
                  allow-clear
                  @change="onChangeTime"
                />
              </a-form-item>
            </a-col>
          </a-row>
          <a-row>
            <a-col :span="7">
              <a-form-item>
                <a-space>
                  <a-button type="primary" @click="onSearch" class="copy">
                    搜索
                  </a-button>
                  <a-button @click="onReset">重置</a-button>
                </a-space>
              </a-form-item>
            </a-col>
          </a-row>
        </a-form>
      </section>
      <section class="table__container">
        <a-table
          :data-source="tblData"
          :columns="tblColumns"
          :loading="loading"
          :pagination="pagination"
          rowKey="siteId"
          @change="onPage"
        >
          <template slot="openTime" slot-scope="text, record">
            {{ record.openTime ? dtFormat(record.openTime) : "--" }}
          </template>
          <template slot="action" slot-scope="text, record">
            <a @click="detail(record)">详情</a>
          </template>
        </a-table>
      </section>
    </div>
  </a-layout>
</template>

<script>
import {
  operationHeadquartersSiteInfo,
  organizationInfoList
} from "@/services/ValueBag";
import { dtFormat } from "@/components/DateUtils";
const statusList = [
  {
    label: "全部",
    value: ""
  },
  {
    label: "未运营",
    value: "shut"
  },
  {
    label: "运营中",
    value: "enable"
  }
];
const tblColumns = [
  {
    title: "站点名称",
    dataIndex: "siteName",
    width: "15%"
  },
  {
    title: "运营商",
    dataIndex: "corporationName",
    width: "10%"
  },
  {
    title: "站长类型",
    dataIndex: "siteTypeValue",
    width: "10%"
  },
  {
    title: "站长名称",
    dataIndex: "siteAdminName",
    width: "15%"
  },
  {
    title: "站长联系方式",
    dataIndex: "siteAdminPhone",
    width: "15%"
  },
  {
    title: "运营状态",
    dataIndex: "statusValue",
    width: "15%"
  },
  {
    title: "运营开始日期",
    dataIndex: "openTime",
    scopedSlots: { customRender: "openTime" },
    width: "20%"
  },
  {
    title: "操作",
    key: "action",
    scopedSlots: { customRender: "action" },
    width: "10%"
  }
];
export default {
  name: "ServiceSite",
  data() {
    return {
      form: this.$form.createForm(this),
      statusList,
      tblData: [],
      loading: false,
      pagination: {
        showQuickJumper: true,
        current: 1,
        total: 0,
        pageSize: 15
      },
      startTime: "",
      endTime: "",
      tblColumns,
      corporationList: [],
      dtFormat
    };
  },
  mounted() {
    this.getCorporationList();
    this.loadList();
  },
  methods: {
    //获取机构下拉列表
    getCorporationList() {
      organizationInfoList("endConsumerMarket").then(resp => {
        this.corporationList = resp.data.data;
      });
    },
    filterInstitutionOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
    // 调整时间
    onChangeTime(date, dateString) {
      if (dateString && dateString.length > 0) {
        this.startTime = dateString[0] ? dateString[0] + " 00:00:00" : "";
        this.endTime = dateString[1] ? dateString[1] + " 23:59:59" : "";
      }
    },
    // 获取表格数据
    loadList() {
      let params = {
        pageNum: this.pagination.current,
        pageSize: this.pagination.pageSize,
        ...this.form.getFieldsValue(),
        startTime: this.startTime,
        endTime: this.endTime
      };
      operationHeadquartersSiteInfo(params).then(resp => {
        this.tblData = resp.data.data.records;
        this.pagination.total = resp.data.data.total;
        this.pagination.showTotal = function(total, range) {
          return `当前显示 ${range[0]} - ${range[1]} 条, 共 ${total} 条`;
        };
      });
    },
    // 搜索
    onSearch() {
      this.pagination.current = 1;
      this.loadList();
    },
    // 重置
    onReset() {
      this.form.resetFields();
      this.startTime = "";
      this.endTime = "";
      this.onSearch();
    },
    // 分页
    onPage(pagination) {
      this.pagination.current = pagination.current;
      this.loadList();
    },
    // 跳转详情页
    detail(item) {
      this.$router.push({
        name: "ServiceSiteDetail",
        query: {
          siteId: item.siteId
        }
      });
    }
  }
};
</script>

<style scoped>
/deep/ .ant-form-item {
  margin-right: 20px;
}
</style>
