<template>
  <a-layout-content>
    <div class="nav">
      <a-breadcrumb>
        <a-breadcrumb-item>配置管理</a-breadcrumb-item>
        <a-breadcrumb-item>站点编辑</a-breadcrumb-item>
      </a-breadcrumb>
    </div>
    <section class="info-container">
      <a-form :form="infoForm">
        <a-row>
          <a-col :span="7">
            <a-form-item
              :label-col="{ span: 4 }"
              :wrapper-col="{ span: 16 }"
              label="站长名称"
            >
              <a-input
                v-decorator="[
                  'serviceName',
                  { rules: [{ required: true, message: '请输入站长名称' }] }
                ]"
                placeholder=""
              />
            </a-form-item>
          </a-col>
          <a-col :span="7">
            <a-form-item
              :label-col="{ span: 4 }"
              :wrapper-col="{ span: 16 }"
              label="地理位置"
            >
              <a-input
                v-decorator="[
                  'serviceName1',
                  { rules: [{ required: true, message: '请选择地理位置' }] }
                ]"
                placeholder=""
                disabled="disabled"
              >
                <template #addonAfter>
                  <span style="cursor: pointer" @click="setAddress()">
                    选择地址
                  </span>
                </template>
              </a-input>
            </a-form-item>
          </a-col>
          <a-col :span="7">
            <a-form-item
              :label-col="{ span: 4 }"
              :wrapper-col="{ span: 16 }"
              label="运营商"
            >
              <a-select
                v-decorator="[
                  'type2',
                  { rules: [{ required: true, message: '请选择运营商' }] }
                ]"
                :options="operateList"
              ></a-select>
            </a-form-item>
          </a-col>
          <a-col :span="7">
            <a-form-item
              :label-col="{ span: 4 }"
              :wrapper-col="{ span: 16 }"
              label="运营时间"
            >
              <a-date-picker
                v-decorator="[
                  'time',
                  { rules: [{ required: true, message: '请选择运营时间' }] }
                ]"
                style="width:100%"
              />
            </a-form-item>
          </a-col>
          <a-col :span="7">
            <a-form-item
              :label-col="{ span: 4 }"
              :wrapper-col="{ span: 16 }"
              label="运营状态"
            >
              <a-select
                v-decorator="[
                  'status',
                  { rules: [{ required: true, message: '请选择运营状态' }] }
                ]"
                :options="statusList"
              ></a-select>
            </a-form-item>
          </a-col>
          <a-col :span="7">
            <a-form-item
              :label-col="{ span: 4 }"
              :wrapper-col="{ span: 16 }"
              label="站长类型"
            >
              <a-select
                v-decorator="['type']"
                :options="typeList"
                disabled
              ></a-select>
            </a-form-item>
          </a-col>
          <a-col :span="7">
            <a-form-item
              :label-col="{ span: 4 }"
              :wrapper-col="{ span: 16 }"
              label="站点管理员"
            >
              <a-input v-decorator="['serviceName2']" disabled />
            </a-form-item>
          </a-col>
          <a-col :span="7">
            <a-form-item
              :label-col="{ span: 4 }"
              :wrapper-col="{ span: 16 }"
              label="联系方式"
            >
              <a-input v-decorator="['serviceName3']" disabled />
            </a-form-item>
          </a-col>
        </a-row>
        <div class="footer">
          <a-button type="primary" @click="onModify">保存</a-button>
          <a-button @click="goBack">取消</a-button>
        </div>
      </a-form>
    </section>
    <a-modal v-model="mapModel" title="地理位置" @ok="handleOk" width="1200px">
      <a-form :form="mapForm">
        <a-row>
          <a-col :span="12">
            <a-form-item
              :label-col="{ span: 4 }"
              :wrapper-col="{ span: 16 }"
              label="搜索地址"
            >
              <a-select
                v-decorator="['areaNameWord']"
                show-search
                defaultOpen
                placeholder="请输入"
                :default-active-first-option="false"
                :show-arrow="false"
                :filter-option="false"
                :not-found-content="null"
                :options="dataAddressList"
                @search="handleSearch"
                @change="handleChange"
              ></a-select>
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
      <div id="container">
        <img src="../../assets/imgs/img.png" alt="" class="map__center" />
      </div>
    </a-modal>
  </a-layout-content>
</template>

<script>
import AMapLoader from "@amap/amap-jsapi-loader";
window._AMapSecurityConfig = {
  securityJsCode: "8bd908ead940215404427b8f61e8e493"
};
const statusList = [
  {
    label: "未运营",
    value: "未运营"
  }
];
const typeList = [
  {
    label: "自有站长",
    value: "自有站长"
  },
  {
    label: "合作站长",
    value: "合作站长"
  }
];
let geocoder = null;
export default {
  name: "ServiceSiteEdit",
  data() {
    return {
      infoForm: this.$form.createForm(this),
      operateList: [],
      statusList,
      typeList,
      mapModel: false,
      nowAddress: [120.210552, 30.240841],
      map: null,
      mapForm: this.$form.createForm(this),
      dataAddressList: []
    };
  },
  methods: {
    setAddress() {
      this.mapModel = true;
      if (!this.map) {
        this.initMap();
      }
    },
    onModify() {
      this.infoForm.validateFields((err, values) => {
        if (!err) {
          console.log(values);
        }
      });
    },
    goBack() {
      this.$router.back();
    },
    // 确认选择
    handleOk() {
      console.log(this.nowAddress);
      geocoder.getAddress(this.nowAddress, function(status, result) {
        if (status === "complete" && result.info === "OK") {
          console.log(result);
          console.log(result.regeocode.formattedAddress);
        }
      });
    },
    handleSearch(val) {
      if (val) {
        let that = this;
        let autoOptions = {
          city: "010"
        };

        that.dataAddressList = [];
        // 实例化AutoComplete
        let autoComplete = new window.AMap.AutoComplete(autoOptions);
        // 根据关键字进行搜索
        autoComplete.search(val, function(status, result) {
          // 搜索成功时，result即是对应的匹配数据
          if (status !== "no_data") {
            result.tips.forEach(item => {
              if (item.id) {
                that.dataAddressList.push({
                  label: item.name,
                  value: item.id,
                  district: item.district,
                  address: item.address,
                  location: item.location
                });
              }
            });
          } else {
            that.dataAddressList = [];
          }
        });
      }
    },
    // 选择地址后触发
    handleChange(val) {
      this.dataAddressList.forEach(item => {
        if (item.value == val) {
          this.searchMap(item);
        }
      });
    },
    // 初始化地图
    initMap() {
      AMapLoader.load({
        key: "8ff794d16e2643c4d0ef597224524cb3", // 申请好的Web端开发者Key，首次调用 load 时必填
        version: "2.0", // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
        plugins: [
          "AMap.Autocomplete", //输入提示插件
          "AMap.PlaceSearch", //POI搜索插件
          "AMap.Scale", //右下角缩略图插件 比例尺
          "AMap.OverView", //地图鹰眼插件
          "AMap.ToolBar", //地图工具条
          "AMap.Geolocation", //定位控件，用来获取和展示用户主机所在的经纬度位置
          "AMap.Marker",
          "AMap.Geocoder" // 逆地理编码,通过经纬度获取地址所在位置详细信息
        ] // 需要使用的的插件列表，如比例尺'AMap.Scale'等
      }).then(AMap => {
        this.map = new AMap.Map("container", {
          // 设置地图容器id
          enableHighAccuracy: true,
          subdomains: [],
          viewMode: "2D", // 是否为3D地图模式
          zoom: 16, // 初始化地图级别
          center: this.nowAddress // 初始化地图中心点位置
        });
        const _this = this;
        this.map.on("moveend", function() {
          _this.nowAddress = [this.getCenter().lng, this.getCenter().lat];
        });
        geocoder = new AMap.Geocoder({
          city: ""
        });
      });
    },
    // 地图缩放至指定级别并以指定点为地图显示中心点
    searchMap(item) {
      this.map.setZoomAndCenter(16, [item.location.lng, item.location.lat]);
    }
  }
};
</script>

<style scoped>
.info-container {
  background-color: white;
  margin: 10px 20px;
  box-sizing: border-box;
  padding: 25px 30px 25px 30px;
}
.footer {
  background-color: white;
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  padding: 10px;
  z-index: 1;
  display: flex;
  justify-content: center;
}
.footer button {
  margin-right: 8px;
}
#container {
  width: 100%;
  height: 500px;
}
.map__center {
  z-index: 1000;
  width: 40px !important;
  height: 40px !important;
  position: absolute;
  bottom: calc(50%);
  left: calc(50% - 20px);
}
</style>
