<template>
  <a-layout>
    <div class="nav">
      <a-breadcrumb>
        <a-breadcrumb-item>租赁管理</a-breadcrumb-item>
        <a-breadcrumb-item>作站长管理</a-breadcrumb-item>
        <a-breadcrumb-item>站长详情修改</a-breadcrumb-item>
      </a-breadcrumb>
    </div>
    <div class="content-container">
      <a-form :form="editForm" class="receive-form">
        <a-row>
          <a-col :span="8">
            <a-form-item
              label="姓名"
              :label-col="formItemLayout.labelCol"
              :wrapper-col="formItemLayout.wrapperCol"
            >
              <a-input disabled v-decorator="['name']"></a-input>
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item
              label="身份证号"
              :label-col="formItemLayout.labelCol"
              :wrapper-col="formItemLayout.wrapperCol"
            >
              <a-input disabled v-decorator="['ID']"></a-input>
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item
              label="手机号"
              :label-col="formItemLayout.labelCol"
              :wrapper-col="formItemLayout.wrapperCol"
            >
              <a-input disabled v-decorator="['mobile']"></a-input>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="8">
            <a-form-item
              label="收款银行"
              :label-col="formItemLayout.labelCol"
              :wrapper-col="formItemLayout.wrapperCol"
            >
              <a-input disabled v-decorator="['corporationName']"></a-input>
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item
              label="收款银行地区"
              :label-col="formItemLayout.labelCol"
              :wrapper-col="formItemLayout.wrapperCol"
            >
              <a-cascader
                disabled
                v-decorator="['AreaCode']"
                :options="areaTreeData"
                :field-names="areaTreeNames"
              />
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item
              label="收款银行卡号"
              :label-col="formItemLayout.labelCol"
              :wrapper-col="formItemLayout.wrapperCol"
            >
              <a-input disabled v-decorator="['corporationName']"></a-input>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="8">
            <a-form-item
              label="佣金(元/单)"
              :label-col="formItemLayout.labelCol"
              :wrapper-col="formItemLayout.wrapperCol"
            >
              <a-input v-decorator="['money']"></a-input>
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item
              label="合作协议"
              :label-col="formItemLayout.labelCol"
              :wrapper-col="formItemLayout.wrapperCol"
            >
              <div>
                <a-upload
                  v-decorator="[
                    'file',
                    {
                      rules: [{ required: true, message: '请上传合作协议' }]
                    }
                  ]"
                  name="file"
                  :multiple="true"
                  :action="`${baseURL}/upload-word/cooperation_agreement`"
                  :headers="headers"
                  :file-list="fileList"
                  @change="value => onChangeReport(value)"
                >
                  <a v-if="fileList.length === 0" class="btn--upload">
                    点击上传
                  </a>
                </a-upload>
              </div>
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item
              label="加入时间"
              :label-col="formItemLayout.labelCol"
              :wrapper-col="formItemLayout.wrapperCol"
            >
              <a-range-picker
                disabled
                :default-value="[
                  moment('2015-06-06', 'YYYY-MM-DD'),
                  moment('2015-06-06', 'YYYY-MM-DD')
                ]"
              />
            </a-form-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="8">
            <a-form-item
              label="站点门头照"
              :label-col="formItemLayout.labelCol"
              :wrapper-col="formItemLayout.wrapperCol"
            ></a-form-item>
          </a-col>
        </a-row>
      </a-form>
      <a-table
        class="commodity__table"
        :columns="siteColumns"
        :data-source="siteList"
        :pagination="false"
        :rowKey="(record, index) => index"
      >
        <a-space slot="action" slot-scope="text, record">
          <a @click="editSite(record)">修改</a>
        </a-space>
      </a-table>
    </div>
    <a-modal
      title="修改站点信息"
      :visible="visible"
      okText="确定"
      cancelText="取消"
      @ok="handleOk"
      @cancel="handleCancel"
    >
      <a-form :form="siteForm" @submit="handleOk">
        <a-form-item
          :label-col="formItemLayout.labelCol"
          :wrapper-col="formItemLayout.wrapperCol"
          label="站点名称"
        >
          <a-input
            v-decorator="[
              'siteName',
              {
                rules: [{ required: true, message: '请输入站点名称' }]
              }
            ]"
          />
        </a-form-item>
        <a-form-item
          :label-col="formItemLayout.labelCol"
          :wrapper-col="formItemLayout.wrapperCol"
          label="运营商"
        >
          <a-select
            placeholder="请选择"
            v-decorator="['distributorTypes', { rules: [{ required: true }] }]"
          >
            <a-select-option
              v-for="item in distributorType"
              :value="item.id"
              :key="item.id"
            >
              {{ item.name }}
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item
          label="运营时间"
          :label-col="formItemLayout.labelCol"
          :wrapper-col="formItemLayout.wrapperCol"
        >
          <a-range-picker
            disabled
            :default-value="[
              moment('2015-06-06', 'YYYY-MM-DD'),
              moment('2015-06-06', 'YYYY-MM-DD')
            ]"
          />
        </a-form-item>
        <a-form-item
          :label-col="formItemLayout.labelCol"
          :wrapper-col="formItemLayout.wrapperCol"
          label="站点状态"
        >
          <a-select
            placeholder="请选择"
            v-decorator="['status', { rules: [{ required: true }] }]"
          >
            <a-select-option
              v-for="item in siteOptions"
              :value="item.value"
              :key="item.name"
            >
              {{ item.name }}
            </a-select-option>
          </a-select>
        </a-form-item>
      </a-form>
    </a-modal>
  </a-layout>
</template>
<script>
import moment from "moment";
import { baseURL, imageBaseUrl } from "@/services/HttpService";
import { buildHeaders } from "@/components/utils";
import { loadArea } from "@/services/DealerService";
const formItemLayout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 16 }
};
const siteColumns = [
  {
    title: "站点名称",
    dataIndex: ""
  },
  {
    title: "运营商",
    dataIndex: ""
  },
  {
    title: "运营时间",
    dataIndex: ""
  },
  {
    title: "站点状态",
    dataIndex: ""
  },
  {
    title: "操作",
    key: "action",
    scopedSlots: { customRender: "action" }
  }
];
export default {
  name: "EditMaster",
  data() {
    return {
      formItemLayout,
      siteColumns,
      baseURL,
      imageBaseUrl,
      headers: buildHeaders(),
      editForm: this.$form.createForm(this),
      siteForm: this.$form.createForm(this),
      siteList: [{}],
      visible: false,
      areaTreeData: [], // 收款银行地区数据
      // 收款银行地区格式
      areaTreeNames: {
        label: "areaName",
        value: "areaCode",
        children: "subAreas"
      },
      currentSitInfo: {},
      distributorType: [], // 运营商列表
      // 站点状态列表
      siteOptions: [
        { name: "运营中", value: "1" },
        { name: "停止运营", value: "0" }
      ],
      time: [],
      fileList: [],
      reportFileList: {}
    };
  },
  mounted() {
    this.onLoadArea();
  },
  methods: {
    moment,
    // 加载行政区数据
    onLoadArea() {
      this.areaTreeData = [];
      loadArea().then(resp => {
        if (resp.data.code === "SUCCESS") {
          this.areaTreeData = resp.data.data;
        } else {
          this.$message.error(resp.data.errorMsg);
        }
      });
    },
    // 获取文件
    onChangeReport({ fileList }) {
      this.reportFileList = {};
      this.fileList = fileList;
      var nFileArr = new Array();
      // eslint-disable-next-line no-plusplus
      for (let index = 0; index < fileList.length; index++) {
        const file = fileList[index];
        let keep = true;
        if (file.status !== "error") {
          if (file.response) {
            if (file.response.code === "FAILED") {
              keep = false;
              this.$message.error(file.response.errorMsg);
              return;
            }
          }
        }
        if (keep) {
          nFileArr.push(file);
        }
      }
      if (nFileArr.length > 0) {
        if (nFileArr[0].response && nFileArr[0].response.data) {
          const params = {
            uid: 1,
            fileUrl: nFileArr[0].response.data,
            name: nFileArr[0].name,
            status: "done",
            url: imageBaseUrl + nFileArr[0].response.data
          };
          const reportParams = {
            fileUrl: nFileArr[0].response.data,
            fileName: nFileArr[0].name
          };
          this.fileList.push(params);
          this.reportFileList = reportParams;
          this.fileList.splice(0, 1);
        }
      } else {
        this.fileList = nFileArr;
      }
    },
    // 打开修改站点
    editSite(record) {
      this.visible = true;
      this.currentSitInfo = record;
    },
    // 弹窗关闭
    handleCancel() {
      this.siteForm.resetFields();
      this.visible = false;
    },
    // 提交表单
    handleOk() {
      this.siteForm.validateFields(async (err, values) => {
        if (!err) {
          console.log(values);
        }
      });
    }
  }
};
</script>

<style scoped></style>
