<template>
  <a-layout>
    <div class="nav">
      <Breadcrumb>
        <BreadcrumbItem>详情</BreadcrumbItem>
      </Breadcrumb>
    </div>
    <section class="card__container">
      <div class="container__title">工单信息</div>
      <a-form class="advanced-search-form">
        <a-row>
          <a-col :span="8">
            <a-form-item label="工单编号">
              <div>{{ info.recordId }}</div>
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="工单类型">
              <div>{{ info.operationTypeValue }}</div>
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="商品名称">
              <div>{{ info.skuName }}</div>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="8">
            <a-form-item label="商品编号">
              <div>{{ info.skuCode }}</div>
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="资产序列号">
              <a @click="toFinanceDetail">{{ info.goodsSn }}</a>
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="提交人">
              <div>{{ info.createUserRealName }}</div>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="8">
            <a-form-item label="创建时间">
              <div>{{ tsFormat(info.createTime) }}</div>
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="订单号">
              <a @click="toOrderDetail">{{ info.orderSn }}</a>
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="业务类型">
              <div>{{ info.schemeModeValue }}</div>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="8">
            <a-form-item label="资产类型">
              <div>{{ info.leaseAssetTypeValue }}</div>
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="运营商">
              <div>{{ info.corporationName }}</div>
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
    </section>
    <section class="card__container">
      <div class="container__title">处理信息</div>
      <a-form class="advanced-search-form">
        <a-row>
          <a-col :span="8">
            <a-form-item label="工单状态">
              <div>{{ info.operationStatusValue }}</div>
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="处理人">
              <div>{{ info.dealUserRealName || "--" }}</div>
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="处理完成时间">
              <div>{{ info.dealTime ? tsFormat(info.dealTime) : "--" }}</div>
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
      <div class="container__title">退款明细</div>
      <a-table
        :data-source="info.paymentDetailVOList"
        :columns="columns"
        :pagination="false"
        :row-key="
          (record, index) => {
            return index;
          }
        "
      >
        <template slot="footer">
          <div>合计(元): {{ totalMount.toFixed(2) }}</div>
        </template>
      </a-table>
    </section>
  </a-layout>
</template>
<script>
import { tsFormat } from "@/components/DateUtils";
import { getWorkOrderDetail } from "@/services/LeaseManage";
import Breadcrumb from "@/components/Breadcrumb.vue";
import BreadcrumbItem from "@/components/BreadcrumbItem.vue";

export default {
  name: "WorkOrderDetail",
  components: { BreadcrumbItem, Breadcrumb },
  data() {
    return {
      id: this.$route.query.id,
      info: {},
      columns: [
        {
          title: "项目",
          dataIndex: "billTypeValue"
        },
        {
          title: "金额(元)",
          dataIndex: "actualPayment"
        }
      ],
      totalMount: 0 // 合计金额
    };
  },
  mounted() {
    if (this.id) {
      this.getDetail();
    }
  },
  methods: {
    tsFormat,
    // 获取用户详情
    async getDetail() {
      const { data } = await getWorkOrderDetail(this.id);
      if (data.code === "SUCCESS") {
        this.info = data.data;
        this.totalMount = data.data.amount;
      } else {
        this.$message.error(data.errorMsg);
      }
    },
    // 跳转资产详情页
    toFinanceDetail() {
      let routeName = "";
      if (this.info.leaseAssetType === "vehicle") {
        routeName = "CarDetail";
      } else {
        routeName = "BatteryDetail";
      }
      // this.$router.push({
      //   name: routeName,
      //   query: { id: this.info.leaseAssetIds }
      // });
      let routeData = this.$router.resolve({
        name: routeName,
        query: { id: this.info.leaseAssetIds }
      });
      window.open(routeData.href, "_blank");
    },
    // 跳转租赁订单详情页
    toOrderDetail() {
      let routeData = this.$router.resolve({
        name: "TrialLeaseDetailDxsj",
        params: {
          id: this.info.orderSn
        }
      });
      window.open(routeData.href, "_blank");
    }
  }
};
</script>
<style scoped></style>
