<template>
  <a-layout>
    <div class="nav">
      <Breadcrumb>
        <BreadcrumbItem>车辆资产详情</BreadcrumbItem>
      </Breadcrumb>
    </div>
    <!-- 基本信息   -->
    <section class="card__container">
      <div class="container__title">基本信息</div>
      <a-form class="advanced-search-form">
        <a-row>
          <a-col :span="8">
            <a-form-item label="商品名称">
              <div>{{ info.skuName }}</div>
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="车辆编号">
              <div>{{ info.skuCode }}</div>
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="序列号">
              <div>{{ info.goodsSn }}</div>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="8">
            <a-form-item label="车牌号">
              <div>{{ info.licencePlate || "--" }}</div>
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="渠道终端">
              <div>{{ info.corporationName }}</div>
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
    </section>
    <section class="card__container">
      <div class="container__title">运营信息</div>
      <a-form class="advanced-search-form">
        <a-row>
          <a-col :span="8">
            <a-form-item label="业务类型">
              <div>{{ info.supportBusinessValue || "--" }}</div>
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="运营状态">
              <div>{{ info.operationStatusValue }}</div>
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="资产状态">
              <div>{{ info.assetStatusValue }}</div>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="8">
            <a-form-item label="投放站点">
              <div>{{ info.siteName || "--" }}</div>
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="当前车辆位置">
              <a @click="openMap">查看</a>
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="骑行状态">
              <div>{{ info.cycleStatusValue }}</div>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="8">
            <a-form-item label="绑定电池编号">
              <div>{{ info.bindAssetCode || "--" }}</div>
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="绑定电池名称">
              <div>{{ info.bindAssetSkuName || "--" }}</div>
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="绑定电池序列号">
              <div>{{ info.bindAssetGoodsSn || "--" }}</div>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="8">
            <a-form-item label="当前租用用户">
              <a v-if="info.customerId" @click="toUserData(info.customerId)">
                查看
              </a>
              <div v-else>--</div>
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="车辆授权状态">
              <div>{{ info.leaseAssetCertState || "--" }}</div>
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="授权期限">
              <div v-if="info.leaseAssetCertState">
                {{ tsFormat(info.startTime) }}至{{ tsFormat(info.endTime) }}
              </div>
              <div v-else>--</div>
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
    </section>
    <section class="card__container">
      <div class="container__title">
        联营机构信息
      </div>
      <a-table
        class="smallTable"
        :columns="regulationColumns"
        :data-source="info.realRightInfoVOList"
        :pagination="false"
        :rowKey="(record, index) => index"
      ></a-table>
      <div class="container__title">
        保证金/物权交易记录
      </div>
      <a @click="openRecordModal">查看详情</a>
    </section>
    <section class="card__container">
      <div class="container__title">租赁订单信息</div>
      <a-table
        :columns="orderColumns"
        :data-source="info.orderVOList"
        :pagination="false"
        :rowKey="(record, index) => index"
      >
        <template slot="orderSn" slot-scope="text, record">
          <a @click="toOrderDetail(record)">{{ record.orderSn }}</a>
        </template>
        <template slot="leaseStartTime" slot-scope="text, record">
          <div>{{ tsFormat(record.leaseStartTime) }}</div>
        </template>
        <template slot="leaseEndTime" slot-scope="text, record">
          <div>{{ tsFormat(record.leaseEndTime) }}</div>
        </template>
        <template slot="customerName" slot-scope="text, record">
          <a @click="toUserData(record.customerId)">
            {{ record.customerName }}
          </a>
        </template>
      </a-table>
    </section>

    <!-- 保证金/物权交易记录弹窗-->
    <a-modal
      :visible="showTable"
      :footer="null"
      title="保证金/物权交易记录"
      width="900px"
      @cancel="showTable = false"
    >
      <a-table
        class="commodity__table"
        :columns="financeColumns"
        :data-source="info.transRecordVOList"
        :pagination="false"
        :rowKey="(record, index) => index"
      ></a-table>
    </a-modal>

    <!-- 地图弹窗-->
    <a-modal
      :visible="mapVisible"
      :footer="null"
      title="车辆当前位置"
      width="900px"
      @cancel="mapVisible = false"
    >
      <div id="container"></div>
    </a-modal>
  </a-layout>
</template>
<script>
import { dtFormat, tsFormat } from "@/components/DateUtils";
import AMapLoader from "@amap/amap-jsapi-loader";
import BreadcrumbItem from "@/components/BreadcrumbItem";
window._AMapSecurityConfig = {
  securityJsCode: "8bd908ead940215404427b8f61e8e493"
};
import { getWebAssetDetail } from "@/services/LeaseManage";
import { imageBaseUrl } from "@/services/HttpService";
import Breadcrumb from "@/components/Breadcrumb.vue";
const regulationColumns = [
  {
    title: "机构名称",
    dataIndex: "corporationName"
  },
  {
    title: "机构类型",
    dataIndex: "corporationType"
  },
  {
    title: "物权占有比例",
    dataIndex: "investRatio"
  },
  {
    title: "分润比例",
    dataIndex: "shareRatio"
  }
];
const orderColumns = [
  {
    title: "订单类型",
    dataIndex: "schemeModeValue"
  },
  {
    title: "订单编号",
    dataIndex: "orderSn",
    scopedSlots: { customRender: "orderSn" }
  },
  {
    title: "客户姓名",
    dataIndex: "customerName",
    scopedSlots: { customRender: "customerName" }
  },
  {
    title: "租期开始时间",
    dataIndex: "leaseStartTime",
    scopedSlots: { customRender: "leaseStartTime" }
  },
  {
    title: "租期结束时间",
    dataIndex: "leaseEndTime",
    scopedSlots: { customRender: "leaseEndTime" }
  },
  {
    title: "订单状态",
    dataIndex: "orderStatusValue"
  }
];
const financeColumns = [
  {
    title: "流水号",
    dataIndex: "rn"
  },
  {
    title: "付款方",
    dataIndex: "paymentCorporationName"
  },
  {
    title: "收款方",
    dataIndex: "accountCorporationName"
  },
  {
    title: "类型",
    dataIndex: "type"
  },
  {
    title: "支付金额(元)",
    dataIndex: "paymentNum"
  },
  {
    title: "支付时间",
    dataIndex: "confirmTime"
  },
  {
    title: "操作人",
    dataIndex: "paymentUserName"
  }
];
export default {
  name: "CarDetail",
  components: { Breadcrumb, BreadcrumbItem },
  data() {
    return {
      id: this.$route.query.id,
      info: {},
      regulationColumns,
      orderColumns,
      financeColumns,
      showTable: false, // 物权交易记录弹窗
      mapVisible: false,
      nowAddress: [],
      map: null
    };
  },
  async mounted() {
    //  获取详情
    const { data } = await getWebAssetDetail(this.id);
    this.info = data.data;
    this.nowAddress = [data.data.longitude, data.data.latitude];
  },
  methods: {
    dtFormat,
    tsFormat,
    // 打开物权交易记录弹窗
    openRecordModal() {
      this.showTable = true;
    },
    // 查看车辆位置
    openMap() {
      this.mapVisible = true;
      if (!this.map) {
        this.initMap();
      }
    },
    // 初始化地图
    initMap() {
      AMapLoader.load({
        key: "8ff794d16e2643c4d0ef597224524cb3", // 申请好的Web端开发者Key，首次调用 load 时必填
        version: "2.0", // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
        plugins: [
          "AMap.AutoComplete",
          "AMap.PlaceSearch",
          "AMap.Scale",
          "AMap.OverView",
          "AMap.ToolBar",
          "AMap.MapType",
          "AMap.PolyEditor",
          "AMap.Geocoder",
          "AMap.AutoComplete",
          "AMap.CircleEditor",
          "AMap.Driving",
          "AMap.service",
          "AMap.DistrictSearch",
          "AMap.Geolocation",
          "AMap.Marker",
          "AMap.LngLat",
          "AMap.Polygon",
          "AMap.Circle"
        ] // 需要使用的的插件列表，如比例尺'AMap.Scale'等
      }).then(AMap => {
        this.map = new AMap.Map("container", {
          // 设置地图容器id
          enableHighAccuracy: true,
          subdomains: [],
          viewMode: "2D", // 是否为3D地图模式
          zoom: 16, // 初始化地图级别
          center: this.nowAddress // 初始化地图中心点位置
        });

        const marker = new AMap.Marker({
          position: new AMap.LngLat(this.info.longitude, this.info.latitude),
          offset: new AMap.Pixel(0, 0),
          icon: imageBaseUrl + "/menu-icon/workIcon.png", // 添加 Icon 图标 URL
          title: ""
        });
        this.map.add(marker);
      });
    },
    // 跳转用户数据-数据详情页
    toUserData(customerId) {
      let routeData = this.$router.resolve({
        name: "webConsumerDetail",
        query: { id: customerId }
      });
      window.open(routeData.href, "_blank");
    },
    // 跳转订单详情页
    toOrderDetail(record) {
      let routeData = this.$router.resolve({
        name: "TrialLeaseOrderDxsj",
        query: { orderSn: record.orderSn }
      });
      window.open(routeData.href, "_blank");
    }
  }
};
</script>

<style scoped>
.container__title {
  margin-bottom: 20px;
}
.smallTable {
  width: 900px;
  margin-bottom: 20px;
}
.map__center {
  z-index: 1000;
  width: 40px !important;
  height: 40px !important;
  position: absolute;
  bottom: calc(50%);
  left: calc(50% - 20px);
}
#container {
  width: 100%;
  height: 500px;
}
</style>
