<template>
  <a-layout>
    <div class="nav">
      <Breadcrumb>
        <BreadcrumbItem>详情</BreadcrumbItem>
      </Breadcrumb>
    </div>
    <section class="card__container">
      <div class="container__title">工单信息</div>
      <a-form class="advanced-search-form">
        <a-row>
          <a-col :span="8">
            <a-form-item label="警报编号">
              <div>{{ info.recordId }}</div>
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="警报类型">
              <div>{{ info.warningTypeValue }}</div>
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="创建时间">
              <div>{{ tsFormat(info.createTime) }}</div>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="8">
            <a-form-item label="资产类型">
              <div>{{ info.leaseAssetTypeValue }}</div>
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="商品名称">
              <div>{{ info.skuName }}</div>
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="商品编号">
              <div>{{ info.skuCode }}</div>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="8">
            <a-form-item label="资产序列号">
              <a @click="toFinanceDetail">{{ info.goodsSn }}</a>
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="订单编号">
              <a @click="toOrderDetail">{{ info.orderSn }}</a>
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="投放站点">
              <a @click="toSiteDetail">{{ info.siteName }}</a>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row>
          <!--离线警报字段-->
          <a-col :span="8" v-if="info.warningType === 'asset_offline_alert'">
            <a-form-item label="离线开始时间">
              <div>{{ tsFormat(info.assetLeaveStartTime) }}</div>
            </a-form-item>
          </a-col>
          <!--欠费警报字段-->
          <a-col :span="8" v-if="info.warningType === 'arrears_alert'">
            <a-form-item label="欠费金额">
              <div>{{ info.amountOwed }}</div>
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="最后检测位置">
              <a @click="openMap">{{ info.position }}</a>
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="当前客户">
              <a @click="toUserData">{{ info.customerName }}</a>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="8">
            <a-form-item label="警报描述">
              <div>{{ transDesp() }}</div>
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="运营商">
              <div>{{ info.corporationName }}</div>
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
    </section>
    <section class="card__container">
      <div class="container__title">处理信息</div>
      <a-form class="advanced-search-form">
        <a-row>
          <a-col :span="8">
            <a-form-item label="处理人">
              <div>{{ info.dealUserName }}</div>
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="处理状态">
              <div>{{ info.dealUserName }}</div>
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="处理开始时间">
              <div>
                {{ info.dealStartTime ? tsFormat(info.dealStartTime) : "--" }}
              </div>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="8">
            <a-form-item label="处理完成时间">
              <div>
                {{ info.dealEndTime ? tsFormat(info.dealEndTime) : "--" }}
              </div>
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="处理方案">
              <div>{{ info.dealWayValue }}</div>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="8">
            <a-form-item label="处理意见">
              <div>{{ info.dealReason || "--" }}</div>
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
    </section>
    <a-modal
      :visible="mapVisible"
      :footer="null"
      title="最后检测资产位置"
      width="900px"
      @cancel="mapVisible = false"
    >
      <div id="container"></div>
    </a-modal>
  </a-layout>
</template>
<script>
import { tsFormat } from "@/components/DateUtils";
import { getOperationAlarmDetail } from "@/services/LeaseManage";
import { imageBaseUrl } from "@/services/HttpService";
import AMapLoader from "@amap/amap-jsapi-loader";
import Breadcrumb from "@/components/Breadcrumb.vue";
import BreadcrumbItem from "@/components/BreadcrumbItem.vue";
window._AMapSecurityConfig = {
  securityJsCode: "8bd908ead940215404427b8f61e8e493"
};
export default {
  name: "OperationAlarmDetail",
  components: { BreadcrumbItem, Breadcrumb },
  data() {
    return {
      imageBaseUrl,
      id: this.$route.query.id,
      info: {},
      mapVisible: false,
      nowAddress: [],
      map: null
    };
  },
  mounted() {
    if (this.id) {
      this.getDetail();
    }
  },
  methods: {
    tsFormat,
    // 获取用户详情
    async getDetail() {
      const { data } = await getOperationAlarmDetail(this.id);
      console.log(data.data);
      if (data.code === "SUCCESS") {
        this.info = data.data;
        this.transDesp();
        this.nowAddress = [data.data.lgt, data.data.lat];
      } else {
        this.$message.error(data.errorMsg);
      }
    },
    // 最后检测位置
    openMap() {
      this.mapVisible = true;
      if (!this.map) {
        this.initMap();
      }
    },
    // 初始化地图
    initMap() {
      AMapLoader.load({
        key: "8ff794d16e2643c4d0ef597224524cb3", // 申请好的Web端开发者Key，首次调用 load 时必填
        version: "2.0", // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
        plugins: [
          "AMap.AutoComplete",
          "AMap.PlaceSearch",
          "AMap.Scale",
          "AMap.OverView",
          "AMap.ToolBar",
          "AMap.MapType",
          "AMap.PolyEditor",
          "AMap.Geocoder",
          "AMap.AutoComplete",
          "AMap.CircleEditor",
          "AMap.Driving",
          "AMap.service",
          "AMap.DistrictSearch",
          "AMap.Geolocation",
          "AMap.Marker",
          "AMap.LngLat",
          "AMap.Polygon",
          "AMap.Circle"
        ] // 需要使用的的插件列表，如比例尺'AMap.Scale'等
      }).then(AMap => {
        this.map = new AMap.Map("container", {
          // 设置地图容器id
          enableHighAccuracy: true,
          subdomains: [],
          viewMode: "2D", // 是否为3D地图模式
          zoom: 16, // 初始化地图级别
          center: this.nowAddress // 初始化地图中心点位置
        });

        const marker = new AMap.Marker({
          position: new AMap.LngLat(this.info.lgt, this.info.lat),
          offset: new AMap.Pixel(0, 0),
          icon: imageBaseUrl + "/menu-icon/workIcon.png", // 添加 Icon 图标 URL
          title: ""
        });
        this.map.add(marker);
      });
    },
    // 警报描述
    transDesp() {
      let str = "";
      // 欠费警报
      if (this.info.warningType === "arrears_alert") {
        str = "欠费金额已达" + this.info.amountOwed + "元";
      } else if (this.info.warningType === "asset_offline_alert") {
        const mins = parseInt(
          (new Date().getTime() - this.info.assetLeaveStartTime) / 60000
        );
        str = "资产离线时长已达" + mins + "分钟";
      }
      return str;
    },
    // 跳转资产详情页
    toFinanceDetail() {
      let routeName = "";
      if (this.info.leaseAssetType === "vehicle") {
        routeName = "CarDetail";
      } else {
        routeName = "BatteryDetail";
      }
      let routeData = this.$router.resolve({
        name: routeName,
        query: { id: this.info.leaseAssetId }
      });
      window.open(routeData.href, "_blank");
    },
    // 跳转站点详情页
    toSiteDetail() {
      let routeData = this.$router.resolve({
        name: "ServiceSiteDetail",
        query: { siteId: this.info.siteId }
      });
      window.open(routeData.href, "_blank");
    },
    // 跳转用户详情页
    toUserData() {
      let routeData = this.$router.resolve({
        name: "webConsumerDetail",
        query: { id: this.info.customerId }
      });
      window.open(routeData.href, "_blank");
    },
    // 跳转订单详情页
    toOrderDetail() {
      let routeData = this.$router.resolve({
        name: "TrialLeaseDetailDxsj",
        params: { id: this.info.orderSn }
      });
      window.open(routeData.href, "_blank");
    }
  }
};
</script>
<style scoped>
#container {
  width: 100%;
  height: 500px;
}
</style>
