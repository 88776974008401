<template>
  <a-layout>
    <div class="nav">
      <Breadcrumb>
        <BreadcrumbItem>查看明细</BreadcrumbItem>
      </Breadcrumb>
    </div>
    <section class="search__container">
      <a-form class="advanced-search-form" :form="form">
        <a-row>
          <a-col :span="8">
            <a-form-item label="交易单号">
              <a-input allow-clear v-decorator="['hfSeqIdKeyWord']" />
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="付款方">
              <a-input allow-clear v-decorator="['payerKeyWord']" />
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="收款方">
              <a-select
                v-decorator="['payeeCorporationId']"
                show-search
                allow-clear
                :filter-option="filterOption"
              >
                <a-select-option
                  v-for="item in corporationList"
                  :key="item.corporationId"
                >
                  {{ item.corporationName }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="8">
            <a-form-item label="交易时间">
              <a-range-picker style="width: 100%" v-decorator="['time']" />
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="是否到账">
              <a-radio-group v-decorator="['orderStatus']">
                <a-radio value="3">已到账</a-radio>
                <a-radio value="1">未到账</a-radio>
              </a-radio-group>
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-space>
              <a-button type="primary" @click="onSearch">搜索</a-button>
              <a-button @click="onReset">重置</a-button>
            </a-space>
          </a-col>
        </a-row>
      </a-form>
    </section>
    <section class="table__container">
      <a-table
        :columns="tblColumns"
        :data-source="tblData"
        :loading="loading"
        :pagination="pagination"
        rowKey="hfSeqId"
        @change="onPage"
      >
        <template slot="payeeCorporationName" slot-scope="text">
          {{ text || "-" }}
        </template>
        <template slot="tradeType" slot-scope="text">
          <span v-if="text === 'ali'">支付宝</span>
          <span v-if="text === 'wechat'">微信</span>
          <span v-if="text === 'debit'">借记卡</span>
          <span v-if="text === 'credit'">借贷卡</span>
          <span v-if="text === 'balance'">余额转账</span>
        </template>
        <template slot="payUser" slot-scope="text, record">
          {{ record.payerUserName }} - {{ record.payerUserMobile }}
        </template>
        <template slot="orderStatus" slot-scope="text">
          {{ text === "3" ? "已到账" : "未到账" }}
        </template>
        <template slot="createTime" slot-scope="text">
          {{ text ? tsFormat(text) : "-" }}
        </template>
      </a-table>
    </section>
  </a-layout>
</template>

<script>
import Breadcrumb from "@/components/Breadcrumb";
import { checkCommissionAccountDetail } from "@/services/PayService";
import { tsFormat } from "@/components/DateUtils";
import { organizationInfoList } from "@/services/ValueBag";
import BreadcrumbItem from "@/components/BreadcrumbItem";
import moment from "moment";

const tblColumns = [
  {
    title: "交易单号",
    dataIndex: "hfSeqId",
    width: "25%"
  },
  {
    title: "付款方",
    scopedSlots: { customRender: "payUser" },
    width: "10%"
  },
  {
    title: "收款方",
    dataIndex: "payeeCorporationName",
    scopedSlots: { customRender: "payeeCorporationName" },
    width: "10%"
  },
  {
    title: "交易类型",
    dataIndex: "tradeType",
    scopedSlots: { customRender: "tradeType" },
    width: "10%"
  },
  {
    title: "实付款（元）",
    dataIndex: "paymentAmount",
    width: "10%"
  },
  {
    title: "到账金额（元）",
    dataIndex: "arrivalAmount",
    width: "10%"
  },
  {
    title: "手续费（元）",
    dataIndex: "feeAmt",
    width: "10%"
  },
  {
    title: "是否到账",
    dataIndex: "orderStatus",
    scopedSlots: { customRender: "orderStatus" },
    width: "5%"
  },
  {
    title: "交易时间",
    dataIndex: "createTime",
    scopedSlots: { customRender: "createTime" },
    width: "10%"
  }
];

export default {
  name: "CommissionAccountDetail",
  components: {
    BreadcrumbItem,
    Breadcrumb
  },
  data() {
    return {
      form: this.$form.createForm(this),
      tblColumns,
      tblData: [],
      loading: false,
      pagination: {
        showQuickJumper: true,
        hideOnSinglePage: true
      },
      tsFormat,
      corporationList: []
    };
  },
  mounted() {
    this.loadList();
    this.loadCorporationList();
  },
  methods: {
    // 加载列表
    loadList() {
      this.loading = true;
      const params = {
        hfSeqIdKeyWord: this.form.getFieldsValue().hfSeqIdKeyWord || "",
        payerKeyWord: this.form.getFieldsValue().payerKeyWord || "",
        payeeCorporationId: this.form.getFieldsValue().payeeCorporationId || "",
        orderStatus: this.form.getFieldsValue().orderStatus || "",
        startTime:
          this.form.getFieldsValue().time &&
          this.form.getFieldsValue().time.length > 0
            ? moment(this.form.getFieldsValue().time[0]).format(
                "YYYY-MM-DD 00:00:00"
              )
            : "",
        endTime:
          this.form.getFieldsValue().time &&
          this.form.getFieldsValue().time.length > 0
            ? moment(this.form.getFieldsValue().time[1]).format(
                "YYYY-MM-DD 11:59:59"
              )
            : "",
        pageNum: this.pagination.current ? this.pagination.current : 1,
        pageSize: this.pagination.pageSize ? this.pagination.pageSize : 10
      };
      checkCommissionAccountDetail(params)
        .then(resp => {
          this.loading = false;
          if (resp.data.code === "SUCCESS") {
            const data = resp.data.data;
            this.tblData = data.records;
            const pagination = { ...this.pagination };
            pagination.current = data.pageNum;
            pagination.total = data.total;
            pagination.pageSize = data.pageSize;
            pagination.showTotal = function(total, range) {
              return `当前显示 ${range[0]} - ${range[1]} 条, 共 ${total} 条`;
            };
            this.pagination = pagination;
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
    // 获取机构列表
    loadCorporationList() {
      organizationInfoList("all").then(resp => {
        if (resp.data.code === "SUCCESS") {
          const data = resp.data.data;
          this.corporationList = data;
        }
      });
    },
    // 分页
    onPage(pagination) {
      this.pagination.current = pagination.current;
      this.loadList();
    },
    // 搜索
    onSearch() {
      this.pagination.current = 1;
      this.loadList();
    },
    // 重置
    onReset() {
      this.form.resetFields();
      this.loadList();
    },
    // 过滤筛选项
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    }
  }
};
</script>

<style scoped>
/deep/ .ant-form-item {
  margin-right: 20px;
}

/deep/ .ant-form-item .ant-form-item-label {
  text-align: left !important;
  min-width: 80px !important;
}
</style>
