<template>
  <a-layout>
    <div class="nav">
      <Breadcrumb>
        <BreadcrumbItem>电池资产详情</BreadcrumbItem>
      </Breadcrumb>
    </div>
    <!-- 基本信息   -->
    <section class="card__container">
      <div class="container__title">基本详情</div>
      <a-form class="advanced-search-form">
        <a-row>
          <a-col :span="8">
            <a-form-item label="商品名称">
              <div>{{ info.skuName }}</div>
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="电池编号">
              <div>{{ info.skuCode }}</div>
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="序列号">
              <div>{{ info.goodsSn }}</div>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="8">
            <a-form-item label="渠道终端">
              <div>{{ info.corporationName || "--" }}</div>
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
    </section>
    <!-- 运营信息 -->
    <section class="card__container">
      <div class="container__title">运营信息</div>
      <a-form class="advanced-search-form">
        <a-row>
          <a-col :span="8">
            <a-form-item label="业务类型">
              <div>{{ info.leaseBusinessTypeValue || "--" }}</div>
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="运营状态">
              <div>{{ info.operationStatusValue }}</div>
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="资产状态">
              <div>{{ info.assetStatusValue }}</div>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="8">
            <a-form-item label="投放站点">
              <div>{{ info.siteName || "--" }}</div>
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="当前电池位置">
              <a @click="openMap">查看</a>
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="骑行状态">
              <div>{{ info.cycleStatusValue || "--" }}</div>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="8">
            <a-form-item label="绑定车辆编号">
              <div>{{ info.bindAssetCode || "--" }}</div>
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="绑定车辆名称">
              <div>{{ info.bindAssetSkuName || "--" }}</div>
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="绑定车辆序列号">
              <div>{{ info.bindAssetGoodsSn || "--" }}</div>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="8">
            <a-form-item label="当前租用用户">
              <a
                v-if="
                  info.customerId &&
                    (info.operationStatusValue === '租用中' ||
                      info.operationStatusValue === '分期购买中')
                "
                @click="toUserData(info.customerId)"
              >
                查看
              </a>
              <div v-else>--</div>
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
    </section>
    <a-modal
      :visible="mapVisible"
      :footer="null"
      title="电池当前位置"
      width="900px"
      @cancel="mapVisible = false"
    >
      <div id="container"></div>
    </a-modal>
  </a-layout>
</template>
<script>
import AMapLoader from "@amap/amap-jsapi-loader";
window._AMapSecurityConfig = {
  securityJsCode: "8bd908ead940215404427b8f61e8e493"
};
import { getWebAssetDetail } from "@/services/LeaseManage";
import { imageBaseUrl } from "@/services/HttpService";
import Breadcrumb from "@/components/Breadcrumb.vue";
import BreadcrumbItem from "@/components/BreadcrumbItem.vue";
export default {
  name: "BatteryDetail",
  components: { BreadcrumbItem, Breadcrumb },
  data() {
    return {
      id: this.$route.query.id,
      info: {},
      mapVisible: false,
      nowAddress: [],
      map: null
    };
  },
  async mounted() {
    //  获取详情
    const { data } = await getWebAssetDetail(this.id);
    this.info = data.data;
    this.nowAddress = [data.data.longitude, data.data.latitude];
  },
  methods: {
    openMap() {
      this.mapVisible = true;
      if (!this.map) {
        this.initMap();
      }
    },
    // 初始化地图
    initMap() {
      AMapLoader.load({
        key: "8ff794d16e2643c4d0ef597224524cb3", // 申请好的Web端开发者Key，首次调用 load 时必填
        version: "2.0", // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
        plugins: [
          "AMap.AutoComplete",
          "AMap.PlaceSearch",
          "AMap.Scale",
          "AMap.OverView",
          "AMap.ToolBar",
          "AMap.MapType",
          "AMap.PolyEditor",
          "AMap.Geocoder",
          "AMap.AutoComplete",
          "AMap.CircleEditor",
          "AMap.Driving",
          "AMap.service",
          "AMap.DistrictSearch",
          "AMap.Geolocation",
          "AMap.Marker",
          "AMap.LngLat",
          "AMap.Polygon",
          "AMap.Circle"
        ] // 需要使用的的插件列表，如比例尺'AMap.Scale'等
      }).then(AMap => {
        this.map = new AMap.Map("container", {
          // 设置地图容器id
          enableHighAccuracy: true,
          subdomains: [],
          viewMode: "2D", // 是否为3D地图模式
          zoom: 16, // 初始化地图级别
          center: this.nowAddress // 初始化地图中心点位置
        });

        const marker = new AMap.Marker({
          position: new AMap.LngLat(this.info.longitude, this.info.latitude),
          offset: new AMap.Pixel(0, 0),
          icon: imageBaseUrl + "/menu-icon/workIcon.png", // 添加 Icon 图标 URL
          title: ""
        });
        this.map.add(marker);
      });
    },
    // 跳转用户数据-数据详情页
    toUserData(customerId) {
      let routeData = this.$router.resolve({
        name: "webConsumerDetail",
        query: { id: customerId }
      });
      window.open(routeData.href, "_blank");
    }
  }
};
</script>
<style scoped>
.map__center {
  z-index: 1000;
  width: 40px !important;
  height: 40px !important;
  position: absolute;
  bottom: calc(50%);
  left: calc(50% - 20px);
}
#container {
  width: 100%;
  height: 500px;
}
</style>
