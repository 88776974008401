<template>
  <a-layout>
    <div class="nav">
      <a-breadcrumb>
        <a-breadcrumb-item>租赁管理</a-breadcrumb-item>
        <a-breadcrumb-item>
          <a @click="goBack">合作站长管理</a>
        </a-breadcrumb-item>
        <a-breadcrumb-item>站长详情</a-breadcrumb-item>
      </a-breadcrumb>
    </div>
    <!-- 基本信息   -->
    <section class="card__container">
      <div class="container__title">基本信息</div>
      <a-form class="advanced-search-form">
        <a-row>
          <a-col :span="8">
            <a-form-item label="姓名">
              <div>{{ info.orderSn }}</div>
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="身份证号">
              <div>{{ info.totalPrice }}</div>
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="手机号">
              <div>{{ info.createTime }}</div>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="8">
            <a-form-item label="收款银行">
              <div>{{ info.orderSn }}</div>
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="收款银行地区">
              <div>{{ info.totalPrice }}</div>
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="收款银行卡号">
              <div>{{ info.totalPrice }}</div>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="8">
            <a-form-item label="佣金">
              <div>{{ info.orderSn }}</div>
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="合作协议">
              <div class="link__url">
                <a :href="imageBaseUrl + info.fileUrl" target="_blank">
                  {{ info.checkCooperationName }}
                </a>
              </div>
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="加入时间">
              <div>{{ info.orderSn }}</div>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="20">
            <a-form-item label="站点门头照">
              <div>{{ info.orderSn }}</div>
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
    </section>

    <section class="card__container">
      <div class="container__title">站点列表</div>
      <a-table
        class="commodity__table"
        :columns="siteColumns"
        :data-source="info.siteList"
        :pagination="false"
        :rowKey="(record, index) => index"
      ></a-table>
    </section>
    <div class="footer">
      <a-button type="primary" @click="editDetail" size="large">修改</a-button>
      <a-button @click="goBack" size="large">返回</a-button>
    </div>
  </a-layout>
</template>
<script>
import { imageBaseUrl } from "@/services/HttpService";

const siteColumns = [
  {
    title: "站点名称",
    dataIndex: ""
  },
  {
    title: "运营商",
    dataIndex: ""
  },
  {
    title: "运营时间",
    dataIndex: ""
  },
  {
    title: "站点状态",
    dataIndex: ""
  }
];
export default {
  name: "WebMasterDetail",
  data() {
    return {
      imageBaseUrl,
      info: {},
      siteColumns
    };
  },
  methods: {
    // 返回上一层
    goBack() {
      this.$router.go(-1);
    },
    // 跳转到修改页面
    editDetail() {
      console.log("修改");
      this.$router.push({
        name: "EditMaster",
        query: { id: this.$route.query.id || "111" }
      });
    }
  }
};
</script>

<style scoped>
.footer {
  text-align: center;
  margin-top: 40px;
}

.footer button {
  margin-right: 8px;
}
</style>
