<!--租赁参数配置 页面-->
<template>
  <a-layout>
    <div class="nav">
      <a-breadcrumb>
        <a-breadcrumb-item>配置管理</a-breadcrumb-item>
        <a-breadcrumb-item>租赁参数配置</a-breadcrumb-item>
      </a-breadcrumb>
    </div>
    <a-form :form="form" class="advanced-search-form">
      <section class="card__container">
        <div class="container__title">分期购车业务费率年化</div>
        <a-row>
          <a-col :span="8" v-if="editMinYear">
            <a-form-item label="最小年化费率">
              <a-input
                v-decorator="['minAnnualPercentageRate']"
                suffix="%"
              ></a-input>
            </a-form-item>
            <a-icon
              type="check"
              class="icon"
              @click="submitItem('editMinYear')"
            />
          </a-col>
          <a-col :span="4" v-else>
            <a-form-item label="最小年化费率">
              {{
                info.minAnnualPercentageRate
                  ? info.minAnnualPercentageRate.toFixed(2)
                  : "--"
              }}%
            </a-form-item>
            <a-icon
              type="edit"
              class="icon"
              @click="changEditStatus('editMinYear', 'minAnnualPercentageRate')"
            />
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="8" v-if="editMaxYear">
            <a-form-item label="最大年化费率">
              <a-input
                v-decorator="['maxAnnualPercentageRate']"
                suffix="%"
              ></a-input>
            </a-form-item>
            <a-icon
              type="check"
              class="icon"
              @click="submitItem('editMaxYear')"
            />
          </a-col>
          <a-col :span="4" v-else>
            <a-form-item label="最大年化费率">
              {{
                info.maxAnnualPercentageRate
                  ? info.maxAnnualPercentageRate.toFixed(2)
                  : "--"
              }}%
            </a-form-item>
            <a-icon
              type="edit"
              class="icon"
              @click="changEditStatus('editMaxYear', 'maxAnnualPercentageRate')"
            />
          </a-col>
        </a-row>
      </section>

      <section class="card__container">
        <div class="container__title">分期购车空租时长</div>
        <a-row>
          <a-col :span="8" v-if="maxEmpty">
            <a-form-item label="最大允许累计空租时长">
              <a-input
                v-decorator="['accumulativeMaximumZeroLeasePeriod']"
                suffix="天"
              ></a-input>
            </a-form-item>
            <a-icon type="check" class="icon" @click="submitItem('maxEmpty')" />
          </a-col>
          <a-col :span="5" v-else>
            <a-form-item label="最大允许累计空租时长">
              {{ info.accumulativeMaximumZeroLeasePeriod || "--" }}天
            </a-form-item>
            <a-icon
              type="edit"
              class="icon"
              @click="
                changEditStatus(
                  'maxEmpty',
                  'accumulativeMaximumZeroLeasePeriod'
                )
              "
            />
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="8" v-if="leaseMaxEmpty">
            <a-form-item label="转租赁后最大允许累计空租时长">
              <a-input
                v-decorator="['accumulativeMaximumZeroLeasePeriodForLease']"
                suffix="天"
              ></a-input>
            </a-form-item>
            <a-icon
              type="check"
              class="icon"
              @click="submitItem('leaseMaxEmpty')"
            />
          </a-col>
          <a-col :span="6" v-else>
            <a-form-item label="转租赁后最大允许累计空租时长">
              {{ info.accumulativeMaximumZeroLeasePeriodForLease || "--" }}天
            </a-form-item>
            <a-icon
              type="edit"
              class="icon"
              @click="
                changEditStatus(
                  'leaseMaxEmpty',
                  'accumulativeMaximumZeroLeasePeriodForLease'
                )
              "
            />
          </a-col>
        </a-row>
      </section>

      <section class="card__container">
        <div class="container__title">
          花呗分期参数配置
          <a-icon
            type="edit"
            class="title-icon"
            @click="setHuaBei = true"
            v-if="!setHuaBei"
          />
        </div>
        <a-row>
          <a-col :span="8">
            <a-form-item label="是否开启花呗分期">
              <a-radio-group
                v-decorator="['huabeiFlag']"
                :disabled="!setHuaBei"
              >
                <a-radio :value="1">开启</a-radio>
                <a-radio :value="0">不开启</a-radio>
              </a-radio-group>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="8">
            <a-form-item label="花呗分期期数设置">
              <a-checkbox-group
                v-decorator="['installmentDuration']"
                :disabled="!setHuaBei"
              >
                <a-checkbox value="3">3期</a-checkbox>
                <a-checkbox value="6">6期</a-checkbox>
                <a-checkbox value="12">12期</a-checkbox>
              </a-checkbox-group>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="8" v-if="setHuaBei">
            <a-form-item label="手续费承担方">
              <a-select
                v-decorator="['serviceChargePayer']"
                placeholder="请选择手续费承担方"
                allow-clear
              >
                <a-select-option
                  v-for="(item, index) in typeList"
                  :key="index"
                  :value="item.value"
                >
                  {{ item.name }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col v-else>
            <a-form-item label="手续费承担方">
              {{ info.serviceChargePayerValue || "--" }}
            </a-form-item>
          </a-col>
        </a-row>
        <div v-if="setHuaBei">
          <a-space size="large">
            <a-button type="primary" @click="submitItem('setHuaBei')">
              提交
            </a-button>
            <a-button @click="cancel('setHuaBei')">取消</a-button>
          </a-space>
        </div>
      </section>
      <section class="card__container" :class="setFile ? '' : 'hiddenDel'">
        <div class="container__title">
          常见问题、协议配置
          <a-icon
            type="edit"
            class="title-icon"
            @click="setFile = true"
            v-if="!setFile"
          />
        </div>
        <a-row>
          <a-col :span="8">
            <a-form-item label="租赁业务常见问题">
              <div>
                <a-upload
                  name="file"
                  accept=".pdf"
                  :action="`${baseURL}/upload/leasing_frequently_questions`"
                  :headers="headers"
                  :file-list="leaseFaqFileList"
                  :before-upload="
                    file => beforeUploadFile(file, 'leaseFaqFileList')
                  "
                  @change="value => onChangeReport(value, 'leaseFaqFileList')"
                >
                  <a-button v-if="leaseFaqFileList.length === 0 && setFile">
                    <a-icon type="upload" />
                    上传文件
                  </a-button>
                  <div v-if="leaseFaqFileList.length === 0 && !setFile">
                    暂无上传
                  </div>
                </a-upload>
              </div>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="8">
            <a-form-item label="租赁用户隐私协议">
              <div>
                <a-upload
                  name="file"
                  accept=".pdf"
                  :action="`${baseURL}/upload/leasing_register_agreement`"
                  :headers="headers"
                  :file-list="leaseRegisterFileList"
                  :before-upload="
                    file => beforeUploadFile(file, 'leaseRegisterFileList')
                  "
                  @change="
                    value => onChangeReport(value, 'leaseRegisterFileList')
                  "
                >
                  <a-button
                    v-if="leaseRegisterFileList.length === 0 && setFile"
                  >
                    <a-icon type="upload" />
                    上传文件
                  </a-button>
                  <div v-if="leaseRegisterFileList.length === 0 && !setFile">
                    暂无上传
                  </div>
                </a-upload>
              </div>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="8">
            <a-form-item label="租赁用户服务协议">
              <div>
                <a-upload
                  name="file"
                  accept=".pdf"
                  :action="`${baseURL}/upload/leasing_service_agreement`"
                  :headers="headers"
                  :file-list="leaseServiceFileList"
                  :before-upload="
                    file => beforeUploadFile(file, 'leaseServiceFileList')
                  "
                  @change="
                    value => onChangeReport(value, 'leaseServiceFileList')
                  "
                >
                  <a-button v-if="leaseServiceFileList.length === 0 && setFile">
                    <a-icon type="upload" />
                    上传文件
                  </a-button>
                  <div v-if="leaseServiceFileList.length === 0 && !setFile">
                    暂无上传
                  </div>
                </a-upload>
              </div>
            </a-form-item>
          </a-col>
        </a-row>
        <div v-if="setFile">
          <a-space size="large">
            <a-button type="primary" @click="submitItem('setFile')">
              保存
            </a-button>
            <a-button @click="cancel('setFile')">取消</a-button>
          </a-space>
        </div>
      </section>

      <section class="card__container">
        <div class="container__title">
          其他配置
        </div>
        <a-row>
          <a-col :span="8" v-if="editWarn">
            <a-form-item label="资产离线警报时长">
              <a-input
                v-decorator="['offlineWarningThreshold']"
                suffix="分钟"
              ></a-input>
            </a-form-item>
            <a-icon type="check" class="icon" @click="submitItem('editWarn')" />
          </a-col>
          <a-col :span="4" v-else>
            <a-form-item label="资产离线警报时长">
              {{ info.offlineWarningThreshold || "--" }}分钟
            </a-form-item>
            <a-icon
              type="edit"
              class="icon"
              @click="changEditStatus('editWarn', 'offlineWarningThreshold')"
            />
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="8" v-if="editStandard">
            <a-form-item label="联合运营投资人标准年化率">
              <a-input
                v-decorator="['investorAnnualPercentageRate']"
                suffix="%"
              ></a-input>
            </a-form-item>
            <a-icon
              type="check"
              class="icon"
              @click="submitItem('editStandard')"
            />
          </a-col>
          <a-col :span="5" v-else>
            <a-form-item label="联合运营投资人标准年化率">
              {{ info.investorAnnualPercentageRate || "--" }}%
            </a-form-item>
            <a-icon
              type="edit"
              class="icon"
              @click="
                changEditStatus('editStandard', 'investorAnnualPercentageRate')
              "
            />
          </a-col>
        </a-row>
      </section>
    </a-form>
  </a-layout>
</template>
<script>
import { baseURL, imageBaseUrl } from "@/services/HttpService";
import { buildHeaders } from "@/components/utils";
import { editBasicConfig, getBasicConfig } from "@/services/LeaseManage";
import { upload } from "@/services/UploadService";

export default {
  name: "SystemSetting",
  data() {
    return {
      baseURL,
      headers: buildHeaders(),
      fileList: [],
      leaseFaqFileList: [], // 租赁常见问题
      leaseRegisterFileList: [], // 租赁用户隐私协议
      leaseServiceFileList: [], // 租赁用户服务协议
      form: this.$form.createForm(this),
      useHuaBeing: false,
      typeList: [{ name: "用户承担", value: "user_responsibility" }],
      // 配置信息
      info: {},
      //  控制 展示内容 还是 对应输入
      editMinYear: false, // 最小年化率
      editMaxYear: false, // 最大年化率
      maxEmpty: false, // 累计空租时长
      leaseMaxEmpty: false, // 转租赁后累计空租时长
      setFile: false, // 协议参数配置
      setHuaBei: false, // 花呗参数配置
      editWarn: false, // 资产离线警报
      editStandard: false // 联合运营人投资标准
    };
  },
  mounted() {
    // 获取配置详情
    this.getBasicConfig();
  },
  methods: {
    // 获取配置详情
    async getBasicConfig() {
      const { data } = await getBasicConfig();
      if (data.code === "SUCCESS") {
        this.info = data.data;
        this.$nextTick(() => {
          this.form.setFieldsValue({
            installmentDuration: data.data.installmentDuration
              ? data.data.installmentDuration.split(",")
              : [],
            huabeiFlag: data.data.huabeiFlag,
            serviceChargePayer: data.data.serviceChargePayer
          });
        });
        if (data.data.leaseFaqFileForm && data.data.leaseFaqFileForm.fileId) {
          this.leaseFaqFileList = [
            {
              uid: data.data.leaseFaqFileForm.fileId,
              fileId: data.data.leaseFaqFileForm.fileId,
              fileUrl: data.data.leaseFaqFileForm.fileUrl,
              fileName: data.data.leaseFaqFileForm.fileName,
              name: data.data.leaseFaqFileForm.fileName,
              url: imageBaseUrl + data.data.leaseFaqFileForm.fileUrl
            }
          ];
        } else {
          this.leaseFaqFileList = [];
        }
        if (
          data.data.leaseRegisterFileForm &&
          data.data.leaseRegisterFileForm.fileId
        ) {
          this.leaseRegisterFileList = [
            {
              uid: data.data.leaseRegisterFileForm.fileId,
              fileId: data.data.leaseRegisterFileForm.fileId,
              fileUrl: data.data.leaseRegisterFileForm.fileUrl,
              fileName: data.data.leaseRegisterFileForm.fileName,
              name: data.data.leaseRegisterFileForm.fileName,
              url: imageBaseUrl + data.data.leaseRegisterFileForm.fileUrl
            }
          ];
        } else {
          this.leaseRegisterFileList = [];
        }
        if (
          data.data.leaseServiceFileForm &&
          data.data.leaseServiceFileForm.fileId
        ) {
          this.leaseServiceFileList = [
            {
              uid: data.data.leaseServiceFileForm.fileId,
              fileId: data.data.leaseServiceFileForm.fileId,
              fileUrl: data.data.leaseServiceFileForm.fileUrl,
              fileName: data.data.leaseServiceFileForm.fileName,
              name: data.data.leaseServiceFileForm.fileName,
              url: imageBaseUrl + data.data.leaseServiceFileForm.fileUrl
            }
          ];
        } else {
          this.leaseServiceFileList = [];
        }
      } else {
        this.$message.error(data.errorMsg);
      }
    },
    // 提交
    async submitItem(flag) {
      const params = this.form.getFieldsValue();
      const obj = {
        id: this.info.id,
        minAnnualPercentageRate: params.minAnnualPercentageRate
          ? params.minAnnualPercentageRate
          : this.info.minAnnualPercentageRate,
        maxAnnualPercentageRate: params.maxAnnualPercentageRate
          ? params.maxAnnualPercentageRate
          : this.info.maxAnnualPercentageRate,
        accumulativeMaximumZeroLeasePeriod: params.accumulativeMaximumZeroLeasePeriod
          ? params.accumulativeMaximumZeroLeasePeriod
          : this.info.accumulativeMaximumZeroLeasePeriod,
        accumulativeMaximumZeroLeasePeriodForLease: params.accumulativeMaximumZeroLeasePeriodForLease
          ? params.accumulativeMaximumZeroLeasePeriodForLease
          : this.info.accumulativeMaximumZeroLeasePeriodForLease,
        huabeiFlag:
          params.huabeiFlag === 1
            ? 1
            : params.huabeiFlag === 0
            ? 0
            : this.info.huabeiFlag,
        installmentDuration: params.installmentDuration
          ? params.installmentDuration.join()
          : this.info.installmentDuration.join(),
        serviceChargePayer: params.serviceChargePayer
          ? params.serviceChargePayer
          : this.info.serviceChargePayer,
        leaseFaqFileForm: this.leaseFaqFileList.length
          ? this.leaseFaqFileList[0]
          : {},
        leaseRegisterFileForm: this.leaseRegisterFileList.length
          ? this.leaseRegisterFileList[0]
          : {},
        leaseServiceFileForm: this.leaseServiceFileList.length
          ? this.leaseServiceFileList[0]
          : {},
        offlineWarningThreshold: params.offlineWarningThreshold
          ? params.offlineWarningThreshold
          : this.info.offlineWarningThreshold,
        investorAnnualPercentageRate: params.investorAnnualPercentageRate
          ? params.investorAnnualPercentageRate
          : this.info.investorAnnualPercentageRate
      };
      const { data } = await editBasicConfig(obj);
      if (data.code === "SUCCESS") {
        this[flag] = false;
        this.getBasicConfig();
      } else {
        this.$message.error(data.errorMsg);
      }
    },
    // 取消 编辑花呗分期参数/协议配置
    cancel(flag) {
      this[flag] = false;
      this.form.resetFields();
      this.getBasicConfig();
    },
    // 上传文件
    onChangeReport({ fileList }, fileListName) {
      this[fileListName] = fileList;
      var nFileArr = new Array();
      // eslint-disable-next-line no-plusplus
      for (let index = 0; index < fileList.length; index++) {
        const file = fileList[index];
        let keep = true;
        if (file.status !== "error") {
          if (file.response) {
            if (file.response.code === "FAILED") {
              keep = false;
              this.$message.error(file.response.errorMsg);
              return;
            }
          }
        }
        if (keep) {
          nFileArr.push(file);
        }
      }
      if (nFileArr.length > 0) {
        if (nFileArr[0].response && nFileArr[0].response.data) {
          const params = {
            uid: 1,
            fileUrl: nFileArr[0].response.data,
            fileName: nFileArr[0].name,
            name: nFileArr[0].name,
            url: imageBaseUrl + nFileArr[0].response.data
          };
          this[fileListName].push(params);
          this[fileListName].splice(0, 1);
        }
      } else {
        this[fileListName] = nFileArr;
      }
    },
    beforeUploadFile(file, fileListName) {
      const size = file.size;
      const fileName = JSON.stringify(file.name);
      if (fileName.length > 100) {
        this.$message.error("文件名称的长度需在100个字以内");
      } else {
        if (size > 20 * 1024 * 1024) {
          this.$message.error("文件大小超过20MB");
        } else {
          this.uploadFile("leasing_frequently_questions", file, fileListName);
        }
      }
      return false;
    },
    async uploadFile(type, file, fileListName) {
      const { data = {} } = await upload(type, file, "other");
      if (data.code === "FAILED") {
        this.$message.error(data.errorMsg);
      } else {
        let list = [];
        list.push({
          uid: list.length,
          name: file.name,
          fileName: file.name,
          url: imageBaseUrl + data.data,
          fileUrl: data.data,
          response: data
        });
        this[fileListName] = [...list];
      }
    },
    // 显示编辑状态
    changEditStatus(flag, field) {
      this[flag] = true;
      this.$nextTick(() => {
        this.form.setFieldsValue({
          [field]: this.info[field]
        });
      });
    }
  }
};
</script>

<style scoped>
/deep/.ant-table-wrapper {
  width: 500px;
}
.container__title {
  margin-bottom: 10px;
}
/deep/ .ant-form-item .ant-form-item-label {
  text-align: left;
}
.icon {
  position: absolute;
  font-size: 18px;
  right: -26px;
  top: 10px;
}
.title-icon {
  font-size: 18px;
  margin-left: 10px;
}
.hiddenDel /deep/.ant-upload-list-item-card-actions {
  display: none;
}
</style>
