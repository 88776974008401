<template>
  <a-layout>
    <div class="nav">
      <Breadcrumb>
        <BreadcrumbItem>详情</BreadcrumbItem>
      </Breadcrumb>
    </div>
    <section class="card__container">
      <div class="container__title">工单信息</div>
      <a-form class="advanced-search-form">
        <a-row>
          <a-col :span="8">
            <a-form-item label="工单编号">
              <div>{{ info.recordId }}</div>
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="工单类型">
              <div>{{ info.maintenanceTypeValue }}</div>
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="资产类型">
              <div>{{ info.leaseAssetTypeValue }}</div>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="8">
            <a-form-item label="资产序列号">
              <a @click="toFinanceDetail">{{ info.goodsSn }}</a>
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="商品编号">
              <div>{{ info.skuCode }}</div>
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="商品名称">
              <div>{{ info.skuName }}</div>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="8">
            <a-form-item label="运营商">
              <div>{{ info.corporationName }}</div>
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="创建时间">
              <div>{{ tsFormat(info.createTime) }}</div>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="8">
            <a-form-item label="工单描述">
              <div>{{ info.description || "--" }}</div>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="24">
            <a-form-item label="处理前图片">
              <div v-if="info.recordPicList && info.recordPicList.length !== 0">
                <img
                  v-for="(item, index) in info.recordPicList"
                  :key="index"
                  class="product__img"
                  :src="imageBaseUrl + item.fileUrl"
                  alt="商品图片"
                />
              </div>
              <div v-else>暂无</div>
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
    </section>
    <section class="card__container">
      <div class="container__title">处理信息</div>
      <a-form class="advanced-search-form">
        <a-row>
          <a-col :span="8">
            <a-form-item label="工单状态">
              <div>{{ info.maintenanceStatusValue }}</div>
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="处理人">
              <div>{{ info.dealUserName || "" }}</div>
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="处理完成时间">
              <div>{{ info.dealTime ? tsFormat(info.dealTime) : "--" }}</div>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="8">
            <a-form-item label="处理意见">
              <div>{{ info.dealReason || "--" }}</div>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="24">
            <a-form-item label="处理后图片">
              <div v-if="info.dealPicList && info.dealPicList.length !== 0">
                <img
                  v-for="(item, index) in info.dealPicList"
                  :key="index"
                  class="product__img"
                  :src="imageBaseUrl + item.fileUrl"
                  alt="商品图片"
                />
              </div>
              <div v-else>暂无</div>
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
    </section>
  </a-layout>
</template>
<script>
import { getOperationOrderDetail } from "@/services/LeaseManage";
import { tsFormat } from "@/components/DateUtils";
import { imageBaseUrl } from "@/services/HttpService";
import Breadcrumb from "@/components/Breadcrumb.vue";
import BreadcrumbItem from "@/components/BreadcrumbItem.vue";
export default {
  name: "OperationDetail",
  components: { BreadcrumbItem, Breadcrumb },
  data() {
    return {
      imageBaseUrl,
      id: this.$route.query.id,
      info: {}
    };
  },
  mounted() {
    if (this.id) {
      this.getDetail();
    }
  },
  methods: {
    tsFormat,
    // 获取用户详情
    async getDetail() {
      const { data } = await getOperationOrderDetail(this.id);
      if (data.code === "SUCCESS") {
        this.info = data.data;
      } else {
        this.$message.error(data.errorMsg);
      }
    },
    // 跳转资产详情页
    toFinanceDetail() {
      let routeName = "";
      if (this.info.leaseAssetType === "vehicle") {
        routeName = "CarDetail";
      } else {
        routeName = "BatteryDetail";
      }
      let routeData = this.$router.resolve({
        name: routeName,
        query: { id: this.info.leaseAssetId }
      });
      window.open(routeData.href, "_blank");
    }
  }
};
</script>
<style scoped>
.container__title {
  margin-bottom: 20px;
}
.product__img {
  height: 100px;
  margin-right: 20px;
}
</style>
